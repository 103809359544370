export const OTEL_MP_DOC_TRACE_RATIO = 0.15

export const OTEL_MP_SAMPLE_TRACE_RATIO = 0.15

export const LOCAL_STORAGE_DEBUG_SESSION_ID_PROP_NAME = 'multiplayer-debug-session-id'

export const LOCAL_STORAGE_DEBUG_SESSION_SHORT_ID_PROP_NAME = 'multiplayer-debug-session-short-id'

export const LOCAL_STORAGE_DEBUG_SESSION_STATE_PROP_NAME = 'multiplayer-debug-session-state'

export const DEFAULT_MAX_HTTP_CAPTURING_PAYLOAD_SIZE = 100000

export const MULTIPLAYER_BASE_API_URL = 'https://api.multiplayer.app'
