import { Fragment } from 'react';
import { Separator } from '@chakra-ui/react';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from 'chakra/snippets/menu';

const Menu = ({ trigger, options, onSelect }) => (
  <MenuRoot
    onSelect={(e) => {
      if (onSelect) {
        onSelect(e.value);
      }
    }}
  >
    <MenuTrigger asChild outline="none">
      { trigger }
    </MenuTrigger>
    <MenuContent>
      {
        options.map(({
          label,
          value,
          icon,
          danger,
          separator,
        }) => (
          <Fragment key={value}>
            <MenuItem
              value={value}
              onClick={e => e.stopPropagation()}
              {
                ...danger && {
                  color: 'fg.error',
                  _hover: { bg: 'bg.error', color: 'fg.error' },
                }
              }
            >
              { icon }
              { label }
            </MenuItem>
            {
              separator && <Separator />
            }
          </Fragment>
        ))
      }
    </MenuContent>
  </MenuRoot>
);

export default Menu;
