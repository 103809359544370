import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';

export const getCompanies = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_COMPANIES });
      const res = await axios.get(`${URL}/companies`);
      return dispatch({
        type: GET_COMPANIES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_COMPANIES_ERROR,
        payload: response && response.data,
      });
      throw response.data;
    }
  };
};

export const createCompany = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/companies`, data);
      return dispatch({
        type: CREATE_COMPANY,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editCompany = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/companies/${id}`, data);
      return dispatch({
        type: EDIT_COMPANY,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response.data;
    }
  };
};
