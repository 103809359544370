import {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from 'chakra/snippets/popover';
import { useDebouncedCallback } from 'use-debounce';
import {
  Checkbox,
} from 'chakra/snippets/checkbox';
import { Box } from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import TableColumnItem from './TableColumnItem';

const TableColumnSelector = memo(({
  trigger,
  colorPalette = 'cyan',
  columns: defaultColumns,
  onChange,
  width = 200,
  height,
  placement = 'bottom-end',
}) => {
  const [columns, setColumns] = useState({});
  const [loading, setLoading] = useState(false);
  const onSave = async () => {
    setLoading(true);
    await onChange(columns);
    setLoading(false);
  };

  const touchCallback = useDebouncedCallback((onSave), 400);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    if (defaultColumns?.length) {
      setColumns(defaultColumns.reduce((acc, num) => {
        acc[num.key] = {
          label: num.title,
          visible: num.visible === undefined ? true : !!num.visible,
          order: num.order,
        };
        return acc;
      }, {}));
    }
  }, [defaultColumns]);

  const items = useMemo(() => Object
    .keys(columns)
    .sort((a, b) => columns[a].order - columns[b].order), [columns]);

  const onSort = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);

      const reorderedColumns = arrayMove(items, oldIndex, newIndex)
        .reduce((acc, key, index) => {
          acc[key] = {
            label: columns[key].label,
            visible: !!columns[key].visible,
            order: index,
          };
          return acc;
        }, {});

      setColumns({
        ...columns,
        ...reorderedColumns,
      });
      touchCallback();
    }
  };

  const onToggle = (e, key) => {
    setColumns(Object.keys(columns).reduce((acc, num) => {
      if (num === key) {
        acc[num] = {
          label: columns[key].label,
          visible: e.checked,
          order: columns[key].order,
        };
        return acc;
      }
      acc[num] = {
        label: columns[num].label,
        order: columns[num].order,
        visible: !!columns[num].visible,
      };
      return acc;
    }, {}));
    touchCallback();
  };

  return (
    <PopoverRoot
      lazyMount
      size="xs"
      unmountOnExit
      positioning={{ placement }}
    >
      <PopoverTrigger asChild>
        { trigger(loading) }
      </PopoverTrigger>
      <PopoverContent
        w={width}
        zIndex={2000}
        borderRadius={1}
      >
        <PopoverArrow />
        <PopoverBody>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={onSort}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <Box
                h={height}
                overflowY="auto"
                overflowX="hidden"
              >
                {
                  items.map(key => (
                    <TableColumnItem
                      key={key}
                      id={key}
                    >
                      <Box p={1}>
                        <Checkbox
                          w="full"
                          cursor="pointer"
                          colorPalette={colorPalette}
                          checked={columns[key].visible}
                          onCheckedChange={(e) => onToggle(e, key)}
                        >
                          { columns[key].label }
                        </Checkbox>
                      </Box>
                    </TableColumnItem>
                  ))
                }
              </Box>
            </SortableContext>
          </DndContext>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  );
});

export default TableColumnSelector;
