import { useState } from 'react';
import { createRoot } from 'react-dom/client';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import Dialog from 'chakra/Dialog';
import ThemeConfig from 'theme';

const ChakraDialog = ({
  title,
  content,
  onSubmit,
  onCancel,
  submitText = 'OK',
  cancelText = 'Cancel',
  submitLoadingText = '',
  submitButtonProps,
  submitButtonIcon,
  cancelButtonProps,
  onClose,
  isOpen,
}) => {
  const handleSubmit = () => {
    if (onSubmit) {
      return onSubmit();
    }
    return onClose();
  };

  const handleCancel = () => {
    if (onCancel) {
      return onCancel();
    }
    return onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      title={title}
      submitText={submitText}
      cancelText={cancelText}
      submitButtonProps={submitButtonProps}
      submitButtonIcon={submitButtonIcon}
      submitLoadingText={submitLoadingText}
      cancelButtonProps={cancelButtonProps}
      onSubmit={handleSubmit}
    >
      { content }
    </Dialog>
  );
};

export const ConfirmDialog = (options) => {
  const container = document.createElement('div');
  document.body.appendChild(container);
  const root = createRoot(container);

  const cleanup = () => {
    root.unmount();
    container.remove();
  };

  const ChakraConfirm = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [loading, setLoading] = useState(false);

    const closeDialog = () => {
      setIsOpen(false);
      setTimeout(() => {
        cleanup();
      }, 1000);
    };

    return (
      <ChakraDialog
        {...options}
        isOpen={isOpen}
        submitButtonProps={{
          ...options.submitButtonProps,
          loading,
        }}
        onClose={closeDialog}
        onSubmit={async () => {
          if (options.onSubmit) {
            setLoading(true);
            await options.onSubmit();
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
          return closeDialog();
        }}
        onCancel={() => {
          if (options.onCancel) {
            options.onCancel();
          }
          closeDialog();
        }}
      />
    );
  };

  root.render(
    <ChakraProvider value={ThemeConfig}>
      <ChakraConfirm />
    </ChakraProvider>,
  );
};
