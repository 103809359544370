import { memo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Box, Flex } from '@chakra-ui/react';
import { LuGripVertical } from 'react-icons/lu';

const TableColumnItem = memo(({
  id,
  children,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Flex
      ref={setNodeRef}
      style={style}
      alignItems="center"
    >
      <Box
        {...attributes}
        {...listeners}
        cursor={isDragging ? 'grabbing' : 'grab'}
      >
        <LuGripVertical size={20} />
      </Box>
      { children }
    </Flex>
  );
});

export default TableColumnItem;
