import {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  LuFilter,
  LuCheck,
  LuX,
  LuSearch,
} from 'react-icons/lu';
import {
  Box,
  Flex,
  HStack,
  Presence,
  Separator,
  Tabs,
  Grid,
} from '@chakra-ui/react';
import {
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "chakra/snippets/popover"
import FormField, { INPUT } from 'chakra/FormField';
import {
  generateTabs,
  getFilterBadges,
  getSegmentedControlColor,
} from './FilterBarService';
import { SegmentedControl } from 'chakra/snippets/segmented-control';
import { Button } from "chakra/snippets/button";
import { Field } from 'chakra/snippets/field';
import FilterBarBadges from './FilterBarBadges';
import dayjs from 'dayjs';

const FilterBar = memo(({
  placeholder = 'Search...',
  placement = 'bottom-start',
  colorPalette,
  initialValues = {},
  filters = {},
  onSubmit,
  onClear,
}) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const segmentedControlColor = useMemo(() => getSegmentedControlColor(colorPalette), [
    colorPalette,
  ]);

  useEffect(() => {
    if (!open) {
      setSelectedFilters(initialValues);
    }
  }, [initialValues, open]);

  const filterBadges = useMemo(() => getFilterBadges({
    selectedFilters,
    filters,
  }), [
    selectedFilters,
    filters,
  ]);

  const tabs = useMemo(() => generateTabs(filters), [
    filters,
  ]);

  const tabNames = useMemo(() => Object.keys(tabs), [
    tabs,
  ]);

  const removeSingleFilter = (field) => {
    setSelectedFilters(Object.keys(selectedFilters).reduce((acc, num) => {
      if (num !== field) {
        acc[num] = selectedFilters[num];
        return acc;
      }
      return acc;
    }, {}));
  }

  return (
    <>
      <Presence
        present={open}
        animationDuration="0.2s"
        zIndex={999}
        position="fixed"
        top="0"
        left="0"
        w="full"
        h="full"
        bg="rgba(10, 31, 68, 0.15)"
        animationName={{
          _open: "fade-in",
          _closed: "fade-out",
        }}
      />
      <PopoverRoot
        lazyMount
        unmountOnExit
        open={open}
        positioning={{ placement }}
        onOpenChange={(e) => {
          if (e.open === true) {
            setOpen(true);
          }
        }}
        onInteractOutside={e => {
          if (e?.detail?.originalEvent?.target?.getAttribute('data-part') === 'root') {
            setOpen(false);
          }
        }}
      >
        <PopoverTrigger asChild flex="1">
          <Button
            h="42px"
            variant="surface"
            bg="#fff"
            _hover={{
              bg: '#fff',
            }}
            _expanded={{
              bg: '#fff',
            }}
            {
              ...filterBadges.length && {
                pl: 1,
                pr: 1
              }
            }
          >
            <Flex
              gap="2"
              justifyContent="left"
              w="full"
            >
              {
                filterBadges.length ? (
                  <FilterBarBadges
                    colorPalette={colorPalette}
                    badges={filterBadges}
                    onClear={() => {
                      onClear();
                      setSelectedFilters({});
                    }}
                  />
                ) : (
                  <HStack w="full">
                    <Flex gap={2} flex={1}>
                      <LuSearch />
                      { placeholder }
                    </Flex>
                    <LuFilter />
                  </HStack>
                )
              }
            </Flex>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          maxW="1200px"
          w="calc(100vw - 450px)"
          borderRadius={2}
          portalled={false}
        >
          <PopoverBody p={0}>
            <Tabs.Root
              colorPalette={colorPalette || 'cyan'}
              defaultValue={tabNames.length ? tabNames[0] : null}
            >
              <Tabs.List
                borderTopLeftRadius={2}
                borderTopRightRadius={2}
                w="full"
              >
                {
                  tabNames.map((tab) => (
                    <Tabs.Trigger
                      h="full"
                      key={tab}
                      value={tab}
                      p="18px 36px"
                    >
                      {
                        tab.toLowerCase()
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                      }
                    </Tabs.Trigger>
                  ))
                }
              </Tabs.List>
              <Box
                p={6}
                h="340px"
                width="full"
                overflow="auto"
              >
                {
                  tabNames.map((tab) => {
                    return (
                      <Tabs.Content
                        key={tab}
                        value={tab}
                        inset="0"
                        pt={0}
                        _open={{
                          animationName: "fade-in",
                          animationDuration: "300ms",
                        }}
                      >
                        <Grid
                          gap={4}
                          xl={{ gridTemplateColumns: "repeat(3, minmax(200px, 1fr))" }}
                          templateColumns="repeat(2, 1fr)"
                        >
                          {
                            tabs[tab].map(({
                              name,
                              field,
                              type,
                              options = [],
                              custom,
                            }) => {
                              if (custom) {
                                const {
                                  component: CustomFilterComponent,
                                  ...customProps
                                } = custom;

                                return (
                                  <CustomFilterComponent
                                    { ...customProps }
                                    key={field}
                                    label={name}
                                    value={selectedFilters[field]}
                                    onChange={(e) => {
                                      if (!e.length) {
                                        removeSingleFilter(field);
                                        return;
                                      }
                                      setSelectedFilters(prevFilters => ({
                                        ...prevFilters,
                                        [field]: e,
                                      }));
                                    }}
                                  />
                                )
                              }

                              if (type === INPUT.DROPDOWN_MULTI) {
                                return (
                                  <FormField
                                    usePortal
                                    key={field}
                                    label={name}
                                    type={INPUT.DROPDOWN_MULTI}
                                    options={options}
                                    value={selectedFilters[field]}
                                    onChange={(e) => {
                                      if (!e.length) {
                                        removeSingleFilter(field);
                                        return;
                                      }
                                      setSelectedFilters(prevFilters => ({
                                        ...prevFilters,
                                        [field]: e,
                                      }))
                                    }}
                                  />
                                )
                              }

                              if (type === INPUT.HASH_SET) {
                                return (
                                  <FormField
                                    usePortal
                                    key={field}
                                    label={name}
                                    type={INPUT.HASH_SET}
                                    options={options}
                                    value={selectedFilters[field]}
                                    onChange={(e) => {
                                      if (!e.length) {
                                        removeSingleFilter(field);
                                        return;
                                      }
                                      setSelectedFilters(prevFilters => ({
                                        ...prevFilters,
                                        [field]: e,
                                      }))
                                    }}
                                  />
                                )
                              }

                              if (type === INPUT.RADIO) {
                                return (
                                  <Field label={name} key={field}>
                                    <SegmentedControl
                                      color={segmentedControlColor}
                                      items={options}
                                      value={selectedFilters[field] || 'all'}
                                      onValueChange={(e) => {
                                        // if (e.value === 'all') {
                                        //   removeSingleFilter(field);
                                        //   return;
                                        // }
                                        setSelectedFilters(prevFilters => ({
                                          ...prevFilters,
                                          [field]: e.value,
                                        }))
                                      }}
                                    />
                                  </Field>
                                )
                              }

                              if (type === INPUT.DATE) {
                                return (
                                  <FormField
                                    key={field}
                                    label={name}
                                    type={INPUT.DATE}
                                    value={selectedFilters[field]}
                                    onChange={(e) => {
                                      if (!e) {
                                        removeSingleFilter(field);
                                        return;
                                      }
                                      setSelectedFilters(prevFilters => ({
                                        ...prevFilters,
                                        [field]: e,
                                      }))
                                    }}
                                  />
                                )
                              }

                              if (type === INPUT.DATE_RANGE) {
                                return (
                                  <FormField
                                    key={field}
                                    label={name}
                                    type={INPUT.DATE_RANGE}
                                    value={selectedFilters[field]}
                                    onChange={(e) => {
                                      if (!e) {
                                        removeSingleFilter(field);
                                        return;
                                      }
                                      setSelectedFilters(prevFilters => ({
                                        ...prevFilters,
                                        [field]: `${dayjs(e[0]).format(
                                          'YYYY-MM-DD',
                                        )} ↔ ${dayjs(
                                          e[1],
                                        ).format('YYYY-MM-DD')}`,
                                      }))
                                    }}
                                  />
                                )
                              }
                            })
                          }
                        </Grid>
                      </Tabs.Content>
                    )
                  })
                }
              </Box>
            </Tabs.Root>
            <Separator />
            <Flex
              pl={6}
              pr={6}
              pt={4}
              pb={4}
              gap={4}
            >
              <Button
                p={6}
                colorPalette={colorPalette || 'cyan'}
                loading={submitting}
                loadingText="Saving"
                disabled={clearing}
                onClick={async () => {
                  setSubmitting(true);
                  await onSubmit(selectedFilters);
                  setSubmitting(false);
                  setOpen(false);
                }}
              >
                <LuCheck />
                Apply
              </Button>
              <Button
                p={6}
                variant="outline"
                colorPalette="red"
                loading={clearing}
                loadingText="Clearing"
                disabled={submitting}
                onClick={async () => {
                  setClearing(true);
                  await onClear();
                  setSelectedFilters({});
                  setClearing(false);
                  setOpen(false);
                }}
              >
                <LuX />
                Clear All
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </PopoverRoot>
    </>
  )
});

export default FilterBar;
