import {
  DialogActionTrigger,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogBody,
  DialogRoot,
  DialogTitle,
} from 'chakra/snippets/dialog';
import { Button } from 'chakra/snippets/button';

const Dialog = ({
  open,
  onClose,
  onSubmit,
  children,
  title,
  fullscreen,
  submitText = 'Save',
  cancelText = 'Cancel',
  submitButtonProps,
  submitLoadingText,
  submitButtonIcon,
  cancelButtonProps,
}) => (
  <DialogRoot
    scrollBehavior="inside"
    lazyMount
    open={open}
    autoFocus={false}
    {
      ...fullscreen && {
        size: 'full',
      }
    }
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{ title }</DialogTitle>
      </DialogHeader>
      <DialogBody>
        { children }
      </DialogBody>
      <DialogFooter>
        <DialogActionTrigger asChild>
          <Button
            variant="outline"
            {...cancelButtonProps}
            onClick={() => onClose()}
          >
            { cancelText }
          </Button>
        </DialogActionTrigger>
        {
          onSubmit ? (
            <Button
              loadingText={submitLoadingText}
              {
                ...submitButtonProps || {
                  colorPalette: 'blue',
                }
              }
              onClick={onSubmit}
            >
              { submitButtonIcon }
              { submitText }
            </Button>
          ) : null
        }
      </DialogFooter>
      <DialogCloseTrigger onClick={() => onClose()} />
    </DialogContent>
  </DialogRoot>
);

export default Dialog;
