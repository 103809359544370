export interface SessionDebuggerOptions {
  /**
   * The API key used to authenticate with the session debugger service.
   */
  apiKey: string;

  /**
   * The version of the application using the session debugger.
   */
  version: string;

  /**
   * The name of the application being debugged.
   */
  application: string;

  /**
   * The environment where the application is running (e.g., 'production', 'staging').
   */
  environment: string;

  /**
   * (Optional) Base URL for the session debugger's exporter API.
   * This allows customization of the API endpoint for sending session data.
   */
  exporterApiBaseUrl?: string;

  /**
   * (Optional) An array of URLs or regular expressions that should be ignored by the session debugger.
   * Any URL that partially matches any regex in this array will not be traced.
   * Additionally, URLs that exactly match any string in the array will also be ignored.
   */
  ignoreUrls?: Array<string | RegExp>;

  /**
   * (Optional) Determines where the record button should be placed on the screen.
   * Possible values:
   *  - 'top-left'
   *  - 'top-right'
   *  - 'bottom-left'
   *  - 'bottom-right'
   */
  recordButtonPlacement?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

  /**
   * (Optional) If false, the session recording widget will be hidden from the UI.
   * Use this option if you want to enable session recording without a visible UI element.
   * @default showWidget = true
   */
  showWidget?: boolean;

  /**
   * (Optional) If true, enables the recording and replaying of canvas elements.
   * Pass `true` to capture canvas interactions in the session recording.
   * @default canvasEnabled = false
   */
  canvasEnabled?: any;

  /**
   * (Optional) Trace ID Ratio for document traces
   * @default 0.15
   */
  docTraceRatio?: number

  /**
   * (Optional) Trace ID Ratio for sampling
   * @default 0.15
   */
  sampleTraceRatio?: number;

  /**
   * (Optional) URLs which should include trace headers when origin doesn't match
   * @default []
   */
  propagateTraceHeaderCorsUrls: string | RegExp | string[] | RegExp[]

  /**
   * (Optional) Schemify http request/response payload for document traces.
   * @default true
   */
  schemifyDocSpanPayload?: boolean

  /**
   * (Optional) Mask payload for debug traces.
   * @default true
   */
  maskDebSpanPayload?: boolean

  /**
   * (Optional) Disable capturing of http request/response payload.
   * @default false
   */
  disableCapturingHttpPayload?: boolean

  /**
   * (Optional) Max capturing http request/response payload size.
   * @default 100000
   */
  maxCapturingHttpPayloadSize?: number;
}

export interface SessionDebuggerConfigs {
  apiKey: string;
  version: string;
  application: string;
  environment: string;
  exporterApiBaseUrl: string;
  ignoreUrls: Array<string | RegExp>;
  recordButtonPlacement: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  showWidget: boolean;
  canvasEnabled: boolean;
  docTraceRatio: number;
  sampleTraceRatio: number;
  propagateTraceHeaderCorsUrls: string | RegExp | string[] | RegExp[];
  schemifyDocSpanPayload?: boolean;
  maskDebSpanPayload?: boolean;
  disableCapturingHttpPayload?: boolean;
  maxCapturingHttpPayloadSize: number;
}

export enum SessionState {
  started = '2',
  paused = '1',
  stopped = '0',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type Breaker = {}
