import { CloseXIcon, LogoSvg, RecordIcon } from './icons'

export class UIManager {
  private recorderButton: HTMLButtonElement
  private initialPopover: HTMLElement
  private finalPopover: HTMLElement
  private previewModal: HTMLElement

  /*private _dragHintIcon = `<div class="mp-drag-hint">${DragIcon}</div>`;*/

  /**
   * Constructor initializes the UIManager with necessary DOM elements
   * @param recorderButton - The main button to start recording
   * @param initialPopover - Popover shown when starting the session
   * @param finalPopover - Popover shown when the session ends
   * @param previewModal - Modal to preview the recorded session
   */
  constructor(
    recorderButton: HTMLButtonElement,
    initialPopover: HTMLElement,
    finalPopover: HTMLElement,
    previewModal: HTMLElement,
  ) {
    this.recorderButton = recorderButton
    this.initialPopover = initialPopover
    this.finalPopover = finalPopover
    this.previewModal = previewModal
  }

  /**
   * Sets the properties for the recorder button, including its class,
   * tooltip, and inner HTML content (Record icon)
   */
  public setRecorderButtonProps() {
    this.recorderButton.className = 'mp-session-debugger-button'
    this.recorderButton.dataset.tooltip = 'Click to record a bug'
    this.recorderButton.innerHTML = `${RecordIcon}`
  }

  /**
   * Sets up the initial popover with its class and inner HTML structure.
   * The popover includes a logo, heading, and start recording button.
   */
  public setInitialPopoverProps() {
    this.initialPopover.className =
      'mp-session-debugger-popover mp-initial-popover hidden'
    this.initialPopover.innerHTML = `
    <div class="mp-session-debugger-popover-content">
      <div class="mp-session-debugger-popover-header">
        <a href="https://www.multiplayer.app" target="_blank" rel="noopener noreferrer" title="Multiplayer">
            <div class="mp-session-debugger-popover-logo">${LogoSvg}</div>
        </a>
        <button class="mp-session-debugger-modal-close" aria-label="Close">${CloseXIcon}</button>
      </div>
      <div class="mp-session-debugger-popover-body">
        <h2>Encountered an issue?</h2>
        <p>Help us improve by sharing what went wrong. We'll record your steps, so we can see exactly what happened.</p>
        <button class="mp-session-debugger-popover-button mp-start-recording">Start bug-hunting!</button>
      </div>
    </div>`
  }

  /**
   * Sets up the final popover with its class and inner HTML structure.
   * The popover allows the user to preview or send the bug report after recording.
   */
  public setFinalPopoverProps() {
    this.finalPopover.className = 'mp-session-debugger-popover hidden'
    this.finalPopover.innerHTML = `
    <div class="mp-session-debugger-popover-content">
      <div class="mp-session-debugger-popover-header">
        <a href="https://www.multiplayer.app" target="_blank" rel="noopener noreferrer" title="Multiplayer">
            <div class="mp-session-debugger-popover-logo">${LogoSvg}</div>
        </a>
        <button class="mp-session-debugger-dismiss-button">Dismiss report</button>
      </div>
      <div class="mp-session-debugger-popover-body">
        <h2>Done capturing?</h2>
        <p>Click the button below to send your bug report. Optionally, feel free to send a message to Multiplayer.</p>
        <textarea placeholder="Add a comment..." class="mp-session-debugger-popover-textarea"></textarea>
        <div class="mp-session-debugger-popover-footer">
          <button class="mp-session-debugger-popover-button mp-preview-recording">Preview</button>
          <button class="mp-session-debugger-popover-button mp-stop-recording">Send</button>
        </div>
      </div>
    </div>`
  }

  /**
   * Sets up the preview modal with its class and inner HTML structure.
   * The modal includes actions for sending or dismissing the recording.
   */
  public setPreviewModalProps() {
    this.previewModal.className = 'mp-session-debugger-modal hidden'
    this.previewModal.innerHTML = `
      <div class="mp-session-debugger-modal-backdrop"></div>
      <div class="mp-session-debugger-modal-content">
        <div class="mp-session-debugger-modal-header">
          <header>Preview recording</header>
          <button class="mp-session-debugger-modal-close" aria-label="Close">&times;</button>
        </div>
        <div class="mp-session-debugger-modal-body">
          <div
            id="mpPlayerFrame"
            class="mp-session-debugger-player-frame"
          >
          </div>
        </div>
        <div class="mp-session-debugger-modal-footer">
          <button class="mp-session-debugger-modal-button mp-dismiss-recording">Dismiss report</button>
          <button class="mp-session-debugger-modal-button mp-send-recording">Send bug report</button>
        </div>
      </div>
    `
  }
}
