import SessionDebugger from '@multiplayer-app/session-debugger';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

try {
  SessionDebugger.init({
    version: 'v1',
    application: 'ramp-web-app',
    maskDebSpanPayload: false,
    environment: process.env.REACT_APP_NODE_ENV,
    apiKey: process.env.REACT_APP_MULTIPLAYER_DEBUGGER_TOKEN,
    propagateTraceHeaderCorsUrls: [
      new RegExp(process.env.REACT_APP_API_ENDPOINT.match(/^(https?:\/\/[^/]+)/)[1], 'i'),
    ],
  });
} catch (e) {
  console.log(e);
}

const AppContainer = <BrowserRouter><App /></BrowserRouter>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(AppContainer);

serviceWorker.unregister();
