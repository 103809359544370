import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/serff-spi/response-drafts`;

export const GET_RESPONSE_DRAFTS = 'GET_RESPONSE_DRAFTS';
export const GET_RESPONSE_DRAFTS_SUCCESS = 'GET_RESPONSE_DRAFTS_SUCCESS';
export const GET_RESPONSE_DRAFTS_ERROR = 'GET_RESPONSE_DRAFTS_ERROR';
export const INVALIDATE_RESPONSE_DRAFTS = 'INVALIDATE_RESPONSE_DRAFTS';

export const UPSERT_RESPONSE_DRAFT = 'UPSERT_RESPONSE_DRAFT';
export const CREATE_RESPONSE_ITEM = 'CREATE_RESPONSE_ITEM';
export const EDIT_RESPONSE_ITEM = 'EDIT_RESPONSE_ITEM';

export const ADD_RESPONSE_DRAFT_FORM = 'ADD_RESPONSE_DRAFT_FORM';
export const EDIT_RESPONSE_DRAFT_FORM = 'EDIT_RESPONSE_DRAFT_FORM';
export const DELETE_RESPONSE_DRAFT_FORM = 'DELETE_RESPONSE_DRAFT_FORM';
export const ADD_RESPONSE_DRAFT_FORM_ATTACHMENT = 'ADD_RESPONSE_DRAFT_FORM_ATTACHMENT';
export const DELETE_RESPONSE_DRAFT_FORM_ATTACHMENT = 'DELETE_RESPONSE_DRAFT_FORM_ATTACHMENT';

export const ADD_RESPONSE_DRAFT_RATE = 'ADD_RESPONSE_DRAFT_RATE';
export const EDIT_RESPONSE_DRAFT_RATE = 'EDIT_RESPONSE_DRAFT_RATE';
export const DELETE_RESPONSE_DRAFT_RATE = 'DELETE_RESPONSE_DRAFT_RATE';
export const ADD_RESPONSE_DRAFT_RATE_ATTACHMENT = 'ADD_RESPONSE_DRAFT_RATE_ATTACHMENT';
export const DELETE_RESPONSE_DRAFT_RATE_ATTACHMENT = 'DELETE_RESPONSE_DRAFT_RATE_ATTACHMENT';

export const ADD_RESPONSE_DRAFT_SUPPORTING = 'ADD_RESPONSE_DRAFT_SUPPORTING';
export const EDIT_RESPONSE_DRAFT_SUPPORTING = 'EDIT_RESPONSE_DRAFT_SUPPORTING';
export const DELETE_RESPONSE_DRAFT_SUPPORTING = 'DELETE_RESPONSE_DRAFT_SUPPORTING';
export const ADD_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT = 'ADD_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT';
export const DELETE_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT = 'DELETE_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT';

export const invalidateResponseDrafts = () => (dispatch) => {
  dispatch({ type: INVALIDATE_RESPONSE_DRAFTS });
};

export const getResponseDrafts = ({
  filingId,
  objectionResourceId,
  limit,
  offset,
}) => {
  setAuthToken(localStorage.getItem('token'));

  const params = {
    filing: filingId,
    objectionResourceId,
    limit,
    offset,
  };

  return async (dispatch) => {
    try {
      dispatch({ type: GET_RESPONSE_DRAFTS });
      const res = await axios.get(`${URL}`, { params });
      dispatch({
        type: GET_RESPONSE_DRAFTS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_RESPONSE_DRAFTS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const upsertResponseDraft = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}`, data);
      dispatch({
        type: UPSERT_RESPONSE_DRAFT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteResponseDraft = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${URL}/${id}`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};


export const createResponseItem = ({ responseDraftId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${responseDraftId}/responses`, data);
      dispatch({
        type: CREATE_RESPONSE_ITEM,
        payload: {
          responseDraftId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editResponseItem = ({ responseDraftId, responseItemId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${responseDraftId}/responses/${responseItemId}`, data);
      dispatch({
        type: EDIT_RESPONSE_ITEM,
        payload: {
          responseDraftId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const submitResponseDraft = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/${id}/submission`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addResponseDraftForm = ({ id, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/changes/forms`, data);
      dispatch({
        type: ADD_RESPONSE_DRAFT_FORM,
        payload: { id, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editResponseDraftForm = ({
  id,
  formId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/changes/forms/${formId}`, data);
      dispatch({
        type: EDIT_RESPONSE_DRAFT_FORM,
        payload: {
          id,
          formId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteResponseDraftForm = ({ id, formId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/changes/forms/${formId}`);
      dispatch({
        type: DELETE_RESPONSE_DRAFT_FORM,
        payload: {
          id,
          formId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addResponseDraftFormAttachment = ({
  id,
  formId,
  attachment,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/changes/forms/${formId}/attachments`, attachment);
      dispatch({
        type: ADD_RESPONSE_DRAFT_FORM_ATTACHMENT,
        payload: {
          id,
          formId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteResponseDraftFormAttachment = ({
  id,
  formId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/changes/forms/${formId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_RESPONSE_DRAFT_FORM_ATTACHMENT,
        payload: {
          id,
          formId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addResponseDraftRate = ({ id, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/changes/rates`, data);
      dispatch({
        type: ADD_RESPONSE_DRAFT_RATE,
        payload: { id, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editResponseDraftRate = ({
  id,
  rateId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/changes/rates/${rateId}`, data);
      dispatch({
        type: EDIT_RESPONSE_DRAFT_RATE,
        payload: {
          id,
          rateId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteResponseDraftRate = ({ id, rateId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/changes/rates/${rateId}`);
      dispatch({
        type: DELETE_RESPONSE_DRAFT_RATE,
        payload: {
          id,
          rateId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addResponseDraftRateAttachment = ({
  id,
  rateId,
  attachment,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/changes/rates/${rateId}/attachments`, attachment);
      dispatch({
        type: ADD_RESPONSE_DRAFT_RATE_ATTACHMENT,
        payload: {
          id,
          rateId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteResponseDraftRateAttachment = ({
  id,
  rateId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/changes/rates/${rateId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_RESPONSE_DRAFT_RATE_ATTACHMENT,
        payload: {
          id,
          rateId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addResponseDraftSupporting = ({ id, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/changes/supportings`, data);
      dispatch({
        type: ADD_RESPONSE_DRAFT_SUPPORTING,
        payload: { id, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editResponseDraftSupporting = ({
  id,
  supportingId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/changes/supportings/${supportingId}`, data);
      dispatch({
        type: EDIT_RESPONSE_DRAFT_SUPPORTING,
        payload: {
          id,
          supportingId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteResponseDraftSupporting = ({ id, supportingId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/changes/supportings/${supportingId}`);
      dispatch({
        type: DELETE_RESPONSE_DRAFT_SUPPORTING,
        payload: {
          id,
          supportingId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addResponseDraftSupportingAttachment = ({
  id,
  supportingId,
  attachment,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/changes/supportings/${supportingId}/attachments`, attachment);
      dispatch({
        type: ADD_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT,
        payload: {
          id,
          supportingId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteResponseDraftSupportingAttachment = ({
  id,
  supportingId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/changes/supportings/${supportingId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT,
        payload: {
          id,
          supportingId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
