import { Box } from "@chakra-ui/react"
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "chakra/snippets/hover-card"

const HoverCard = ({
  trigger,
  children,
  placement = 'bottom',
}) => {
  return (
    <HoverCardRoot
      size="sm"
      openDelay={100}
      closeDelay={100}
      positioning={{ placement }}
    >
      <HoverCardTrigger asChild>
        { trigger }
      </HoverCardTrigger>
      <HoverCardContent maxWidth="340px">
        <HoverCardArrow />
        <Box pl={3} pr={3}>
          { children }
        </Box>
      </HoverCardContent>
    </HoverCardRoot>
  )
}

export default HoverCard;
