import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useContext } from 'react';
import {
  ScrollMenu,
  VisibilityContext,
} from 'react-horizontal-scrolling-menu';
import {
  Badge,
  Box,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import {
  LuChevronLeft,
  LuChevronRight,
  LuX,
} from 'react-icons/lu';
import { Button } from 'chakra/snippets/button';
import { Tooltip } from 'chakra/snippets/tooltip';

const LeftArrow = () => {
  const {
    useLeftArrowVisible,
    scrollPrev,
  } = useContext(VisibilityContext);

  const hideLeftArrow = useLeftArrowVisible();

  if (hideLeftArrow) {
    return null;
  }

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        scrollPrev();
      }}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '50px',
        height: '100%',
        zIndex: 99,
        background: 'linear-gradient(to left, transparent, #fff 50%)',
        paddingLeft: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      }}
    >
      <LuChevronLeft />
    </Box>
  );
};

const RightArrow = () => {
  const {
    useRightArrowVisible,
    scrollNext,
  } = useContext(VisibilityContext);

  const hideRightArrow = useRightArrowVisible();

  if (hideRightArrow) {
    return null;
  }

  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        right: '55px',
        width: '50px',
        height: '100%',
        zIndex: 99,
        background: 'linear-gradient(to right, transparent, #fff 50%)',
        paddingRight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      }}
      onClick={(e) => {
        e.stopPropagation();
        scrollNext();
      }}
    >
      <LuChevronRight />
    </Box>
  );
};

const FilterBarBadges = ({
  colorPalette = 'orange',
  badges,
  onClear,
}) => (
  <HStack w="full">
    <Flex w="calc(100% - 55px)">
      <Box
        w="full"
        display="inline-block"
        overflow="hidden"
      >
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
        >
          {
            badges.map(badge => {
              const content = `${badge.label} : ${badge.value}`;
              const badgeContent = (
                <>
                  <Text color="black">{ badge.label }</Text>
                  <Text truncate fontWeight="semibold"> : { badge.value }</Text>
                </>
              );

              return (
                <Badge
                  itemId={badge.field}
                  closable
                  key={badge.label}
                  variant="surface"
                  colorPalette={colorPalette}
                  size="sm"
                  mr={1}
                  p={2}
                  pl={4}
                  pr={4}
                >
                  {
                    content.length >= 45 ? (
                      <Tooltip
                        content={content}
                        openDelay={200}
                        closeDelay={100}
                        positioning={{ placement: 'top' }}
                      >
                        <Flex maxWidth="300px">
                          { badgeContent }
                        </Flex>
                      </Tooltip>
                    ) : badgeContent
                  }
                </Badge>
              );
            })
          }
        </ScrollMenu>
      </Box>
    </Flex>
    <Tooltip
      content="Clear All"
      openDelay={0}
      closeDelay={0}
      positioning={{ placement: 'top' }}
    >
      <Button
        variant="ghost"
        size="sm"
        _hover={{
          colorPalette: 'red',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClear();
        }}
      >
        <LuX />
      </Button>
    </Tooltip>
  </HStack>
);

export default FilterBarBadges;
