import { memo } from 'react';
import { List, Text } from '@chakra-ui/react';
import HoverCard from 'chakra/HoverCard';

const TableColumnHoverCard = memo(({ title, plain, data = [] }) => {
  if (!data?.length) {
    return null;
  }

  if (data?.length === 1) {
    return (
      <Text as="span">
        { data[0] }
      </Text>
    )
  }

  return (
    <HoverCard
      placement="top"
      trigger={(
        <Text
          as="span"
          cursor="default"
          onClick={e => e.stopPropagation()}
        >
          { title }
        </Text>
      )}
    >
      <List.Root variant={plain ? 'plain' : 'marker'}>
        {
          data?.map(item => (
            <List.Item
              key={item}
              cursor="default"
            >
              { item }
            </List.Item>
          ))
        }
      </List.Root>
    </HoverCard>
  )
});

export default TableColumnHoverCard;