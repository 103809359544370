import { memo } from 'react';
import { Text, Box, HStack } from '@chakra-ui/react';
import { TfiFiles } from 'react-icons/tfi';
import { renderFileIcon } from 'chakra/Attachment/Attachment';
import { Tooltip } from 'chakra/snippets/tooltip';
import TableColumnHoverCard from '../TableColumnHoverCard';

const Attachment = memo(({ data, onClick, wrapWithTooltip }) => {
  const icon = renderFileIcon(data.name, 28);

  return (
    <Box
      {
        ...onClick && {
          onClick,
          cursor: 'pointer',
        }
      }
    >
      {
        wrapWithTooltip ? (
          <Tooltip
            openDelay={100}
            closeDelay={100}
            content={data.name}
            positioning={{ placement: "bottom" }}
          >
            { icon }
          </Tooltip>
        ) : icon
      }
    </Box>
  )
});

const TableColumnAttachments = memo(({ attachments, onClick }) => {
  if (!attachments.length) {
    return null;
  }

  if (attachments.length === 1) {
    return (
      <Attachment
        wrapWithTooltip
        key={attachments[0]._id}
        data={attachments[0]}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) {
            onClick(attachments[0]);
          }
        }}
      />
    )
  }

  return (
    <TableColumnHoverCard
      plain
      title={<TfiFiles size={22} />}
      data={
        attachments.map(attachment => {
          const name = (
            <Text truncate>
              { attachment.name }
            </Text>
          );

          return (
            <HStack
              maxW="280px"
              cursor="pointer"
              key={attachment._id}
              onClick={(e) => {
                e.stopPropagation();
                if (onClick) {
                  onClick(attachment);
                }
              }}
            >
              <Attachment
                key={attachment._id}
                data={attachment}
              />
              {
                attachment.name.length > 32 ? (
                  <Tooltip
                    openDelay={100}
                    closeDelay={100}
                    content={attachment.name}
                    positioning={{ placement: "bottom" }}
                  >
                    { name }
                  </Tooltip>
                ) : name
              }
            </HStack>
          )
        })
      }
    />
  )
});

export default TableColumnAttachments;