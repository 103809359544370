import { connect } from 'react-redux';
import {
  currentUser,
  getUserPreferences,
  hasAnalyticsFeature,
  hasFilingDraftsFeature,
  hasRampDriveFeature,
  hasWorkflowChatFeature,
  hasWorkflowFeature,
  hasCircularsFeature,
} from '../../selectors/selectors';
import NavBar from './NavBar';
import { withRouter } from '../../helpers/hooks';

const mapStateToProps = (state) => ({
  user: currentUser(state),
  userPreferences: getUserPreferences(state),
  hasWorkflow: hasWorkflowFeature(state),
  hasRampDrive: hasRampDriveFeature(state),
  hasWorkflowChat: hasWorkflowChatFeature(state),
  hasFilingDrafts: hasFilingDraftsFeature(state),
  hasAnalyticsFeature: hasAnalyticsFeature(state),
  hasCircularsFeature: hasCircularsFeature(state),
  unreadMentions: state.chat.unreadMentions,
});

export default connect(mapStateToProps)(withRouter(NavBar));
