import { useEffect, useRef } from 'react';
import cs from 'classnames';
import SessionDebugger from '@multiplayer-app/session-debugger';
import './SessionDebuggerWidget.scss';

const SessionDebuggerWidget = ({ isExpanded }) => {
  const containerRef = useRef();
  const btn = SessionDebugger.sessionWidgetButtonElement;

  useEffect(() => {
    if (btn) {
      const isLeftSide = btn.classList.contains('button-leftside');
      if (!isLeftSide) {
        btn.classList.add('button-leftside');
      }
      btn.classList.add('no-draggable', 'no-tooltip');
      containerRef.current.append(btn);
      return () => {
        btn.classList.remove('no-draggable', 'no-tooltip');
        if (!isLeftSide) {
          btn.classList.remove('button-leftside');
        }
        const mpRoot = document.querySelector('mp-root');
        if (mpRoot) {
          mpRoot.append(btn);
        } else {
          document.body.append(btn);
        }
      };
    }
  }, [isExpanded]);

  const handleNavItemClick = () => {
    if (btn) {
      const mouseDownEvent = new MouseEvent('mousedown', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      const mouseUpEvent = new MouseEvent('mouseup', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      btn.dispatchEvent(mouseDownEvent);
      setTimeout(() => {
        document.dispatchEvent(mouseUpEvent);
      }, 100);
    }
  };
  return (
    <div ref={containerRef} className="ramp-session-debugger-widget" />
  );
};

export default SessionDebuggerWidget;
