import axios from 'axios';
import { setAuthToken } from './index';
import { renderStateName } from '../services/StateService';
import {
  GET_FILING_FORM_STATUS,
  GET_FILING_FORM_STATUS_ERROR,
  GET_FILING_FORM_STATUS_SUCCESS,
} from './filingActions';

const LIBRARY_URL = `${process.env.REACT_APP_API_ENDPOINT}/library`;

export const GET_LIBRARY_FORMS = 'GET_LIBRARY_FORMS';
export const GET_LIBRARY_FORMS_SUCCESS = 'GET_LIBRARY_FORMS_SUCCESS';
export const GET_LIBRARY_FORMS_ERROR = 'GET_LIBRARY_FORMS_ERROR';

export const GET_LIBRARY_FORM_STATUSES = 'GET_LIBRARY_FORM_STATUSES';
export const GET_LIBRARY_FORM_STATUSES_SUCCESS = 'GET_LIBRARY_FORM_STATUSES_SUCCESS';
export const GET_LIBRARY_FORM_STATUSES_ERROR = 'GET_LIBRARY_FORM_STATUSES_ERROR';

export const GET_LIBRARY_FORMS_COUNT = 'GET_LIBRARY_FORMS_COUNT';
export const GET_LIBRARY_FORMS_COUNT_SUCCESS = 'GET_LIBRARY_FORMS_COUNT_SUCCESS';
export const GET_LIBRARY_FORMS_COUNT_ERROR = 'GET_LIBRARY_FORMS_COUNT_ERROR';

export const ADD_LIBRARY_FORM_CUSTOM_FIELD = 'ADD_LIBRARY_FORM_CUSTOM_FIELD';
export const EDIT_LIBRARY_FORM_CUSTOM_FIELD = 'EDIT_LIBRARY_FORM_CUSTOM_FIELD';
export const DELETE_LIBRARY_FORM_CUSTOM_FIELD = 'DELETE_LIBRARY_FORM_CUSTOM_FIELD';

export const getLibraryForms = (ids) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_LIBRARY_FORMS });
      const res = await axios.get(`${LIBRARY_URL}/library-forms`, {
        params: { ids },
      });
      dispatch({
        type: GET_LIBRARY_FORMS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_LIBRARY_FORMS_ERROR,
        payload: response && response.data,
      });
      throw response.data;
    }
  }
}

export const getLibraryFormStatuses = (params, currentPage, dispatchChanges) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      if (dispatchChanges) {
        dispatch({ type: GET_LIBRARY_FORM_STATUSES });
      }
      const res = await axios.post(`${LIBRARY_URL}/library-form-statuses/search`, params);
      const formStatusList = res?.data?.items || [];

      if (formStatusList) {
        const groupedStatuses = Object
          .groupBy(formStatusList, ({ libraryFormId }) => libraryFormId);

        const lastItemId = formStatusList[formStatusList.length - 1]?._id;

        const forms = Object.keys(groupedStatuses).reduce((acc, num) => {
          const { formNumber, editionDate } = groupedStatuses[num][0];
          acc.push({
            _id: num,
            formNumber,
            editionDate,
            attachments: [],
            statuses: groupedStatuses[num].sort((a, b) => {
              const aState = a?.state?.value || a?.state?.original;
              const bState = b?.state?.value || b?.state?.original;

              const aStateName = renderStateName(aState) || aState;
              const bStateName = renderStateName(bState) || bState;

              return aStateName?.localeCompare(bStateName);
            }),
          });
          return acc;
        }, []);

        if (dispatchChanges) {
          dispatch({
            type: GET_LIBRARY_FORM_STATUSES_SUCCESS,
            payload: {
              items: forms,
              currentPage,
              lastItemId,
            },
          });
        }
        return {
          forms,
          items: formStatusList,
          lastItemId,
        }
      }
    } catch ({ response }) {
      if (dispatchChanges) {
        dispatch({
          type: GET_LIBRARY_FORM_STATUSES_ERROR,
          payload: response && response.data,
        });
      }
      throw response.data;
    }
  };
};

export const getLibraryFormsCount = (params, dispatchChanges) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      if (dispatchChanges) {
        dispatch({
          type: GET_LIBRARY_FORMS_COUNT,
        });
      }
      const res = await axios.post(`${LIBRARY_URL}/library-form-statuses/count-grouped`, params);
      if (dispatchChanges) {
        dispatch({
          type: GET_LIBRARY_FORMS_COUNT_SUCCESS,
          payload: res.data?.count || 0,
        });
      }
      return res.data;
    } catch ({ response }) {
      if (dispatchChanges) {
        dispatch({
          type: GET_LIBRARY_FORMS_COUNT_ERROR,
        });
      }
      throw response.data;
    }
  }
}

export const editLibraryFormStatusBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-form-statuses/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryFormStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-form-statuses/custom-fields/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryFormStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-form-statuses/custom-fields/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryFormStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-form-statuses/custom-fields/bulk`, {
        data: payload,
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const scanLibraryForms = () => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-form-statuses/scan`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addFormStatusReplaces = ({ statusId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-form-statuses/${statusId}/replaces`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteFormStatusReplaces = ({ statusId, replaceId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-form-statuses/${statusId}/replaces/${replaceId}`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryFormStatusCustomField = ({
  formId,
  formStatusId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-form-statuses/${formStatusId}/custom-fields`, data);
      dispatch({
        type: ADD_LIBRARY_FORM_CUSTOM_FIELD,
        payload: {
          formId,
          formStatusId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryFormStatusCustomField = ({
  formId,
  formStatusId,
  customFieldRecordId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-form-statuses/${formStatusId}/custom-fields/${customFieldRecordId}`, data);
      dispatch({
        type: EDIT_LIBRARY_FORM_CUSTOM_FIELD,
        payload: {
          formId,
          formStatusId,
          customFieldRecordId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryFormStatusCustomField = ({
  formId,
  formStatusId,
  customFieldRecordId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-form-statuses/${formStatusId}/custom-fields/${customFieldRecordId}`);
      dispatch({
        type: DELETE_LIBRARY_FORM_CUSTOM_FIELD,
        payload: {
          formId,
          formStatusId,
          customFieldRecordId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryFormStatus = (statusId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-form-statuses/${statusId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const cloneLibraryFormStatus = (statusId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-form-statuses/${statusId}/clone`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryFormStatus = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-form-statuses`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryFormAttachment = ({ formId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-forms/${formId}/attachments/${attachmentId}`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryFormAttachment = ({
  formId,
  attachmentId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-forms/${formId}/attachments/${attachmentId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryFormAttachment = ({ formId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-forms/${formId}/attachments`, attachment);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryForm = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-forms`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getLibraryForm = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${LIBRARY_URL}/library-forms/${id}`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getLibraryFormMap = ({
  formNumber,
  editionDate,
  archived,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${LIBRARY_URL}/library-form-statuses/map`, {
        params: {
          formNumber,
          editionDate,
          archived,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getStatusesByFormId = (params, dispatchChanges) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      if (dispatchChanges) {
        dispatch({
          type: GET_FILING_FORM_STATUS,
        });
      }
      const res = await axios.get(`${LIBRARY_URL}/library-form-statuses`, {
        params,
      });
      if (dispatchChanges) {
        dispatch({
          type: GET_FILING_FORM_STATUS_SUCCESS,
          payload: res.data,
        });
      }
      return res?.data;
    } catch ({ response }) {
      if (dispatchChanges) {
        dispatch({
          type: GET_FILING_FORM_STATUS_ERROR,
        });
      }
      throw response.data;
    }
  };
};
