export const GLOBAL_SEARCH_TYPE = {
  ATTACHMENT: 'attachment',
  FILING: 'filing',
};

export const DRIVE_SEARCH_TYPE = {
  CONTENT: 'content',
  FILES: 'files',
};

export const INPUT_TYPE = {
  DROPDOWN: 'DROPDOWN',
  DROPDOWN_MULTI: 'DROPDOWN_MULTI',
  TEXT_RANGE: 'TEXT_RANGE',
  DATE: 'DATE',
  RADIO: 'RADIO',
  HASH_SET: 'HASH_SET',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
};

export const AUTH_TYPES = {
  RAMP: 'FilingRamp',
  AZURE_AD: 'Azure Active Directory',
  SAML: 'SAML',
};

export const SORTING = {
  asc: -1,
  ascend: -1,
  desc: 1,
  descend: 1,
  '-1': 'asc',
  '1': 'desc',
};

export const SYSTEM_ROLE = {
  MEMBER: 'MEMBER',
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
  ADMIN: 'ADMIN',
};

export const SYSTEM_ROLES = [
  {
    label: 'Member',
    value: SYSTEM_ROLE.MEMBER,
  },
  {
    label: 'Account Admin',
    value: SYSTEM_ROLE.ACCOUNT_ADMIN,
  },
  {
    label: 'Super Admin',
    value: SYSTEM_ROLE.ADMIN,
  },
];

export const FILING_STATUS = {
  INITIAL: 'INITIAL',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  QUEUED: 'QUEUED',
};

export const SYSTEM_ROLE_DISPLAY = {
  MEMBER: 'Member',
  ACCOUNT_ADMIN: 'Account Admin',
  ADMIN: 'Super Admin',
};

export const SERFF_PROTOCOL = {
  REST: 'REST',
  SOAP: 'SOAP',
};

export const SERFF_TYPE = {
  ONE_WAY: 'ONE_WAY',
  TWO_WAY: 'TWO_WAY',
};

export const SOURCE = {
  SERFF: 'SERFF',
  RAMP: 'RAMP',
  SPI: 'SPI',
  RAMP_SCANNED: 'RAMP_SCANNED',
  RAMP_SCRAPER_FL: 'RAMP_SCRAPER_FL',
  RAMP_SCRAPER_SERFF: 'RAMP_SCRAPER_SERFF',
  RAMP_ORACLE_TRACKER: 'RAMP_ORACLE_TRACKER',
};

export const EVENT = {
  OBJECTION: 'objection',
  RESPONSE: 'response',
  DISPOSITION: 'disposition',
  AMENDMENT: 'amendment',
  NOTE: 'note',
};

export const eventTypes = [
  {
    label: 'Objection Letter',
    value: EVENT.OBJECTION,
  },
  {
    label: 'Response',
    value: EVENT.RESPONSE,
  },
  {
    label: 'Amendment',
    value: EVENT.AMENDMENT,
  },
  {
    label: 'Disposition',
    value: EVENT.DISPOSITION,
  },
];

export const statuses = [
  {
    name: 'Approved as Amended',
    value: 'approved as amended',
  },
  {
    name: 'Approved/Effective',
    value: 'closed-approved',
  },
  {
    name: 'Disapproved',
    value: 'rejected',
  },
  {
    name: 'Withdrawn',
    value: 'withdrawn',
  },
];

export const SUPPORTING = {
  BYPASS: 'BYPASS',
  SATISFY: 'SATISFY',
};

export const STATUS_COLORS = {
  'submitted-assigned': '#1890ff',
  // Objection Letter
  'pending-response': '#ea434c',
  // Response Sent
  'pending-state-action': '#1890ff',
  // Approved/Effective
  'closed-approved': '#19B182',
  // Desk Filing
  'closed-approved-desk': '#19B182',
  // Rejected
  'closed-rejected': '#ea434c',
  // Closed Withdrawn
  'closed-withdrawn': '#ea434c',
  // Approved Revision
  'closed-revised': '#19B182',
};

export const STATUS_IDS = {
  'submitted-assigned': 'Submitted Assigned',
  // Objection Letter
  'pending-response': 'Pending Response',
  // Response Sent
  'pending-state-action': 'Pending State Action',
  // Approved/Effective
  'closed-approved': 'Closed Approved',
  // Desk Filing
  'closed-approved-desk': 'Closed Approved Desk',
  // Rejected
  'closed-rejected': 'Closed Rejected',
  // Closed Withdrawn
  'closed-withdrawn': 'Closed Withdrawn',
  // Approved Revision
  'closed-revised': 'Closed Revised',
};

export const MAP_COLORS = {
  APPROVED: '#19b182',
  PENDING: '#1890ff',
  WITHDRAWN: '#ea434c',
  REJECTED: '#ea434c',
};

export const EVENTS = {
  submitted: 'Submitted',
  objection: 'Objection',
  response: 'Response',
  amendment: 'Amendment',
  note: 'Note',
  disposition: 'Disposition',
};

export const CUSTOM_FIELDS = {
  STRING: 'STRING',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  LINK: 'LINK',
  DROPDOWN: 'DROPDOWN',
  DROPDOWN_MULTI: 'DROPDOWN_MULTI',
  HASH_SET: 'HASH_SET',
};

export const BUSINESS_TYPES = {
  P: 'P&C',
  L: 'LAH',
};

export const FORM_LABELS = {
  trackingNumber: 'Tracking Number',
  formName: 'Form Name',
  formNumber: 'Form Number',
  editionDate: 'Edition Date',
  readabilityScore: 'Readability Score',
  formType: 'Form Type',
  action: 'Action',
  leadFormNumber: 'Lead Form Number',
  resourceId: 'Resource ID',
  previousFilingNumber: 'Previous Filing Number',
  replacedFormNumber: 'Replaced Form Number',
  replacedEditionDate: 'Replaced Edition Date',
  otherExplanation: 'Other Explanation',
  reviewStatus: 'Review Status',
  userCreated: 'Created By',
  userSubmitted: 'Submitted By',
  userLastModified: 'Last Updated By',
  dateCreated: 'Date Created',
  dateLastModified: 'Date Last Modified',
  dateSubmitted: 'Date Submitted',
  hasPreviousVersion: 'Has Previous Version',
  confidential: 'Is Confidential?',
  publicAccess: 'Is Public?',
};

export const RATE_LABELS = {
  trackingNumber: 'Tracking Number',
  rateName: 'Rate Name',
  ruleNumber: 'Rule Number',
  action: 'Action',
  resourceId: 'Resource ID',
  revisionPercent: 'Revision Percent',
  affectedFormNumbers: 'Affected Form Numbers',
  reviewStatus: 'Review Status',
  previousFilingNumber: 'Previous Filing Number',
  otherExplanation: 'Other Explanation',
  confidential: 'Is Confidential?',
  hasPreviousVersion: 'Has Previous Version',
  publicAccess: 'Is Public?',
  userCreated: 'Created By',
  userLastModified: 'Last Updated By',
  userSubmitted: 'Submitted By',
  dateCreated: 'Date Created',
  dateLastModified: 'Date Last Modified',
  dateSubmitted: 'Date Submitted',
};

export const GENERAL_INFORMATION_LABELS = {
  advisoryOrganizationCircular: 'Advisory Organization Circular',
  companyTrackingNumber: 'Company Tracking Number',
  correspondingTrackingNumber: 'Corresponding Tracking Number',
  description: 'Filing Description',
  domicileStatus: 'Domicile Status',
  domicileStatusComment: 'Domicile Status Comment',
  effectiveDateRequestedNew: 'Effective Date Requested (New)',
  effectiveDateRequestedNewUponApproval: 'Upon Approval',
  effectiveDateRequestedRenewal: 'Effective Date Requested (Renewal)',
  effectiveDateRequestedRenewalUponApproval: 'Upon Approval',
  referenceNumber: 'Reference Number',
  referenceOrganization: 'Reference Organization',
  referenceTitle: 'Reference Title',
};

export const MOZART_FORMS_PAGINATION_SETTINGS = {
  rootPageSize: 'mozart-forms-root-page-size',
  rootPageOffset: 'mozart-forms-root-page-offset',
  formsPageSize: 'mozart-forms-page-size',
  formsPageOffset: 'mozart-forms-page-offset',
};
