import { createSystem, defaultConfig } from '@chakra-ui/react';

export default createSystem(defaultConfig, {
  theme: {
    tokens: {
      shadows: {
        primary: { value: '0 0 4px 0 rgba(10, 31, 68, 0.08), 0 1px 1px 0 rgba(10, 31, 68, 0.08)' },
      },
      colors: {
        black: { value: '#0A1F44' },
        primary: { value: '#0a91b1' },
      },
      fonts: {
        body: { value: 'Inter, sans-serif' },
        heading: { value: 'Inter, sans-serif' },
      },
    },
  },
});
