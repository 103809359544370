import {
  Box,
  Flex,
  Stack,
  Text,
  FormatByte,
} from '@chakra-ui/react';
import {
  LuEllipsisVertical,
  LuDownload,
  LuFileSearch,
  LuGitCompare,
  LuTrash2,
} from 'react-icons/lu';
import Menu from 'chakra/Menu';
import { Tooltip } from 'chakra/snippets/tooltip';
import { Button } from 'chakra/snippets/button';
import { ProgressBar, ProgressRoot } from 'chakra/snippets/progress';
import {
  PiFileLight,
  PiFileCsvLight,
  PiFileDocLight,
  PiFileHtmlLight,
  PiFileJpgLight,
  PiFilePdfLight,
  PiFilePngLight,
  PiFileTxtLight,
  PiFileXlsLight,
  PiFileZipLight,
} from 'react-icons/pi';

export const renderFileIcon = (filename, size) => {
  if (!filename) {
    return null;
  }

  const extension = filename
    .split('.')
    .pop()
    .trim()
    .toLowerCase();

  switch (extension) {
    case 'pdf':
      return <PiFilePdfLight size={size} />;
    case 'jpg':
      return <PiFileJpgLight size={size} />;
    case 'png':
      return <PiFilePngLight size={size} />;
    case 'html':
    case 'htm':
      return <PiFileHtmlLight size={size} />;
    case 'zip':
      return <PiFileZipLight size={size} />;
    case 'xls':
    case 'xlsx':
    case 'xlsm':
      return <PiFileXlsLight size={size} />;
    case 'doc':
    case 'docx':
      return <PiFileDocLight size={size} />;
    case 'txt':
      return <PiFileTxtLight size={size} />;
    case 'csv':
      return <PiFileCsvLight size={size} />;
    case 'json':
    case 'xml':
    default:
      return <PiFileLight size={size} />;
  }
};

/**
 * Represents the current state of asynchronous operations.
 * @typedef {Object} AttachmentState
 * @property {boolean} downloading - Indicates if downloading is currently in progress.
 * @property {string|null} error - Error message if an error occurred; otherwise, null.
 * @property {boolean} uploading - Indicates if uploading is currently in progress.
 * @property {boolean} removing - Indicates if removing (deletion) is currently in progress.
 */

const Attachment = ({
  data,
  state,
  compact,
  onRemove,
  onDownload,
  onCompare,
  onView,
}) => (
  <Stack
    p={4}
    w="full"
    borderRadius={4}
    borderWidth="1px"
    borderColor="border.disabled"
    position="relative"
    {
      ...compact && {
        h: '90px',
        w: "120px",
        p: 2,
      }
    }
  >
    <Flex
      alignItems="center"
      {
        ...compact && {
          direction: 'column',
          h: '100%',
        }
      }
    >
      <Box
        {
          ...!compact ? {
            mr: 2
          } : {
            mb: 2
          }
        }
      >
        { renderFileIcon(data.name, 40) }
      </Box>
      <Stack
        flex={1}
        w={compact ? '100%' : 'calc(100% - 215px)'}
        {
          ...compact && {
            textAlign: 'center'
          }
        }
      >
        <Tooltip
          openDelay={100}
          content={data.name}
        >
          <Text
            cursor="default"
            lineClamp="1"
            textStyle="sm"
            {
              ...!compact && {
                mr: 2,
              }
            }
          >
            { data.name }
          </Text>
        </Tooltip>
        {
          data.size ? (
            <Text truncate textStyle="xs">
              <FormatByte value={data.size} />
            </Text>
          ) : null
        }
        {
          state?.error ? (
            <Text color="red" truncate textStyle="xs">
              { state?.error }
            </Text>
          ) : null
        }
      </Stack>
      {
        !compact ? (
          <>
            {
              !state?.uploading && onCompare ? (
                <Tooltip
                  openDelay={0}
                  closeDelay={0}
                  content="Compare"
                  positioning={{ placement: "top" }}
                >
                  <Button
                    p={2}
                    variant="ghost"
                    colorPalette="gray"
                    onClick={() => onCompare()}
                  >
                    <LuGitCompare />
                  </Button>
                </Tooltip>
              ) : null
            }
            {
              !state?.uploading && onView ? (
                <Tooltip
                  openDelay={0}
                  closeDelay={0}
                  content={state?.downloading ? 'Viewing' : 'View'}
                  positioning={{ placement: "top" }}
                >
                  <Button
                    p={2}
                    variant="ghost"
                    colorPalette="gray"
                    loading={state?.viewing}
                    onClick={() => onView()}
                  >
                    <LuFileSearch />
                  </Button>
                </Tooltip>
              ) : null
            }
            {
              !state?.uploading && onDownload ? (
                <Tooltip
                  openDelay={0}
                  closeDelay={0}
                  content={state?.downloading ? 'Downloading' : 'Download'}
                  positioning={{ placement: "top" }}
                >
                  <Button
                    p={2}
                    variant="ghost"
                    colorPalette="gray"
                    loading={state?.downloading}
                    onClick={() => onDownload()}
                  >
                    <LuDownload />
                  </Button>
                </Tooltip>
              ) : null
            }
            {
              !state?.uploading && onRemove ? (
                <Tooltip
                  openDelay={0}
                  closeDelay={0}
                  content={state?.removing ? 'Removing' : 'Remove'}
                  positioning={{ placement: "top" }}
                >
                  <Button
                    p={2}
                    variant="ghost"
                    colorPalette="red"
                    loading={state?.removing}
                    onClick={() => onRemove()}
                  >
                    <LuTrash2 />
                  </Button>
                </Tooltip>
              ) : null
            }
          </>
        ) : (
          <>
            {
              onView || onRemove || onDownload || onCompare ? (
                <Menu
                  onSelect={(action) => {
                    if (action === 'view') {
                      onView();
                    }

                    if (action === 'download') {
                      onDownload();
                    }

                    if (action === 'compare') {
                      onCompare();
                    }

                    if (action === 'remove') {
                      onRemove();
                    }
                  }}
                  trigger={(
                    <Button
                      pos="absolute"
                      right={0}
                      top={0}
                      pl={2}
                      pr={2}
                      colorPalette="gray"
                      variant="ghost"
                      size="sm"
                    >
                      <LuEllipsisVertical />
                    </Button>
                  )}
                  options={
                    [
                      ...onView ? [{
                        label: 'View',
                        value: 'view',
                        icon: <LuFileSearch />,
                      }] : [],
                      ...onDownload ? [{
                        label: 'Download',
                        value: 'download',
                        icon: <LuDownload />,
                      }] : [],
                      ...onCompare ? [{
                        label: 'Compare',
                        value: 'compare',
                        icon: <LuGitCompare />,
                      }] : [],
                      ...onRemove ? [{
                        label: 'Remove',
                        value: 'remove',
                        icon: <LuTrash2 />,
                        danger: true,
                      }] : [],
                    ]
                  }
                />
              ) : null
            }
          </>
        )
      }
    </Flex>
    {
      state?.uploading ? (
        <ProgressRoot
          left={0}
          bottom={0}
          w="full"
          size="xs"
          value={null}
          colorPalette="cyan"
          position="absolute"
        >
          <ProgressBar />
        </ProgressRoot>
      ) : null
    }
  </Stack>
);

export default Attachment;
