import axios from 'axios';
import {
  URL, setAuthToken,
} from './index';

/* open objections */
export const LOAD_OPEN_OBJECTIONS = 'load_open_objections';
export const LOAD_OPEN_OBJECTIONS_SUCCESS = 'open_objections_success';
export const LOAD_OPEN_OBJECTIONS_ERROR = 'open_objections_error';

/* closed objections */
export const LOAD_CLOSED_OBJECTIONS = 'load_closed_objections';
export const LOAD_CLOSED_OBJECTIONS_SUCCESS = 'closed_objections_success';
export const LOAD_CLOSED_OBJECTIONS_ERROR = 'closed_objections_error';

const setToken = () => {
  setAuthToken(localStorage.getItem('token'));
};

export const getOpenObjectionList = (params) => {
  setToken();

  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_OPEN_OBJECTIONS });
      const res = await axios.post(`${URL}/search/filings/objections-open`, params);
      dispatch({
        type: LOAD_OPEN_OBJECTIONS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: LOAD_OPEN_OBJECTIONS_ERROR });
      throw response && response.data;
    }
  };
};

export const getClosedObjectionList = (params) => {
  setToken();

  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_CLOSED_OBJECTIONS });
      const res = await axios.post(`${URL}/search/filings/objections-closed`, params);
      dispatch({
        type: LOAD_CLOSED_OBJECTIONS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({ type: LOAD_CLOSED_OBJECTIONS_ERROR });
      throw response && response.data;
    }
  };
};

export const toggleObjectionStatus = ({
  filingId,
  objectionId,
  isHidden,
}) => {
  setToken();
  return async () => {
    try {
      const res = await axios.put(`${URL}/filings-meta/${filingId}/objections/${objectionId}/is-hidden`, { isHidden });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
