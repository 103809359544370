import { groupBy } from 'lodash';
import { INPUT } from 'chakra/FormField';
import { CUSTOM_FIELDS } from 'enums';

export const getSegmentedControlColor = (colorPalette) => {
  if (!colorPalette || colorPalette === 'cyan') {
    return '#0a91b1'
  }

  if (colorPalette === 'purple') {
    return '#9333ea'
  }
}

export const generateTabs = (filters) => {
  return groupBy(filters, (e) => {
    if (!e.tab) {
      return 'FILTERS'
    }
    return e.tab;
  });
}

const getCustomFieldType = (type) => {
  if (['STRING', 'TEXT', 'NUMBER', 'HASH_SET', 'LINK'].includes(type)) {
    return INPUT.HASH_SET;
  }

  if (['DROPDOWN', 'DROPDOWN_MULTI'].includes(type)) {
    return INPUT.DROPDOWN_MULTI;
  }

  if (type === 'BOOLEAN') {
    return INPUT.RADIO;
  }

  if (type === 'DATE') {
    return INPUT.DATE_RANGE;
  }
}

export const generateCustomFieldFilterList = (customFields = {}, tab) => Object
  .keys(customFields)
  .reduce((acc, num) => {
    const field = customFields[num];
    if (!field.archived) {
      const type = getCustomFieldType(field.valueType);

      acc.push({
        isCustomField: true,
        name: field.name,
        field: field._id,
        tab,
        order: field.order,
        type: getCustomFieldType(field.valueType),
        ...type === INPUT.DROPDOWN_MULTI && {
          options: field.dropdownValues.map(dropdownValue => ({
            label: dropdownValue.value,
            value: dropdownValue._id,
          })).sort((a, b) => a?.label?.localeCompare(b?.label)),
        },
        ...type === INPUT.RADIO && {
          options: [{
            label: 'All',
            value: 'all',
          }, {
            label: 'Yes',
            value: 'true',
          }, {
            label: 'No',
            value: 'false',
          }],
        }
      });
      return acc;
    }

    return acc;
  }, []).sort((a, b) => a.order - b.order)

export const generateCustomFieldFiltersPayload = (filters, customFields, field) => Object.keys(filters)
  .reduce((acc, customFieldId) => {
    const value = filters[customFieldId];
    const componentType = customFields[customFieldId]?.valueType;

    if ((value !== undefined && value !== null && typeof value !== 'object')
      || (value && value.length)) {

      if (componentType === CUSTOM_FIELDS.NUMBER) {
        acc.push({
          field,
          value: {
            value: value.map(n => parseInt(n, 10)),
            customFieldId,
          },
          comparisonOperator: 'in',
        });
        return acc;
      }

      if (componentType === CUSTOM_FIELDS.BOOLEAN) {
        if (value !== 'all') {
          acc.push({
            field,
            value: {
              value: value === 'true',
              customFieldId,
            },
            comparisonOperator: 'eq',
          });
        }
        return acc;
      }

      if ([
        CUSTOM_FIELDS.STRING,
        CUSTOM_FIELDS.TEXT,
        CUSTOM_FIELDS.HASH_SET,
        CUSTOM_FIELDS.DROPDOWN,
        CUSTOM_FIELDS.DROPDOWN_MULTI,
        CUSTOM_FIELDS.LINK
      ].includes(componentType)) {
        acc.push({
          field,
          value: {
            value: value.map(v => v.value),
            customFieldId,
          },
          comparisonOperator: 'in',
        });
        return acc;
      }

      if (componentType === CUSTOM_FIELDS.DATE) {
        const [start, end] = value && value.split(' ↔ ');
        acc.push({
          field,
          value: {
            value: `${start}:00:00:00`,
            customFieldId,
          },
          comparisonOperator: 'gte',
        }, {
          field,
          value: {
            value: `${end}:23:59:59`,
            customFieldId,
          },
          comparisonOperator: 'lte',
        });
        return acc;
      }
      return acc;
    }
    return acc;
  }, []);

export const getFilterBadges = ({
  selectedFilters,
  filters,
}) => Object.keys(selectedFilters).reduce((acc, num) => {
  const filter = filters.find(filter => filter.field === num);

  if (!filter || filter.isBadgeHidden && filter.isBadgeHidden(selectedFilters[num])) {
    return acc;
  }

  if (filter.type === INPUT.RADIO) {
    const value = filter?.options?.find(option => option.value === selectedFilters[num]);

    acc.push({
      label: filter.name,
      value: value?.label || selectedFilters[num],
      field: filter.field,
    });
    return acc;
  }

  if (filter.type === INPUT.DROPDOWN_MULTI || filter.type === INPUT.HASH_SET) {
    acc.push({
      label: filter.name,
      value: selectedFilters[num].map(value => value.label).join(', '),
      field: filter.field,
    });
    return acc;
  }

  if (filter.type === INPUT.DATE_RANGE) {
    acc.push({
      label: filter.name,
      value: selectedFilters[num],
      field: filter.field,
    });
    return acc;
  }
  return acc;
}, []);