import { useState } from 'react';
import {
  Box,
  Float,
  HStack,
} from '@chakra-ui/react';
import { Field } from 'chakra/snippets/field';
import { Tooltip } from 'chakra/snippets/tooltip';
import { Button } from 'chakra/snippets/button';
import {
  LuCheck,
  LuPencil,
  LuReply,
  LuX,
} from 'react-icons/lu';
import { INPUT } from './index';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import Number from './Number';
import Switch from './Switch';
import Textarea from './Textarea';
import Input from './Input';

const FieldWrapper = ({
  children,
  label,
  required,
  helperText,
  invalid,
  overridden,
  errorText,
  readOnly,
  disabled,
  resetTooltipText,
  inline,
  value,
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <Box position="relative" w="100%">
      <Field
        label={label}
        required={required}
        helperText={helperText}
        invalid={invalid}
        errorText={errorText}
        {
          ...overridden && {
            color: 'primary',
          }
        }
        optionalText={(
          <>
            {
              overridden && !readOnly && !disabled && (
                <Float offsetX="2" offsetY="2">
                  <Tooltip
                    openDelay={0}
                    closeDelay={0}
                    content={resetTooltipText}
                    positioning={{ placement: 'top' }}
                  >
                    <LuReply
                      cursor="pointer"
                      size="18px"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    />
                  </Tooltip>
                </Float>
              )
            }
            {
              inline && (
                <Float offsetX={editing ? '34px' : 4} offsetY="2">
                  {
                    editing ? (
                      <>
                        <Button
                          p={0}
                          size="xs"
                          colorPalette="red"
                          variant="subtle"
                          color="gray"
                          mr={1}
                          onClick={() => setEditing(false)}
                        >
                          <LuX />
                        </Button>
                        <Button
                          p={0}
                          size="xs"
                          colorPalette="blue"
                          variant="subtle"
                          color="gray"
                          onClick={() => setEditing(false)}
                        >
                          <LuCheck />
                        </Button>
                      </>
                    ) : (
                      <Button
                        p={0}
                        size="xs"
                        variant="ghost"
                        colorPalette="blue"
                        color="gray"
                        onClick={() => setEditing(true)}
                      >
                        <LuPencil />
                      </Button>
                    )
                  }
                </Float>
              )
            }
          </>
        )}
      >
        <HStack w="full">
          {
            inline ? (
              <>
                {
                  editing ? children : (
                    <HStack fontSize="sm" borderTopWidth={1} h="40px" w="full">
                      { value }
                    </HStack>
                  )
                }
              </>
            ) : children
          }
        </HStack>
      </Field>
    </Box>
  );
};

const FormField = ({
  type = INPUT.STRING,
  label,
  selectProps,
  disabled = false,
  readOnly = false,
  usePortal = false,
  required = false,
  invalid = false,
  overridden = false,
  disableClear = false,
  helperText = '',
  errorText = '',
  resetTooltipText = 'Reset',
  options = [],
  placeholder,
  value,
  inline,
  onChange,
}) => (
  <FieldWrapper
    label={label}
    required={required}
    helperText={helperText}
    invalid={invalid}
    overridden={overridden}
    errorText={errorText}
    readOnly={readOnly}
    disabled={disabled}
    resetTooltipText={resetTooltipText}
    inline={inline}
    value={value}
  >
    {
      type === INPUT.STRING ? (
        <Input
          readOnly={readOnly}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          invalid={invalid}
          overridden={overridden}
          onChange={e => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      ) : null
    }

    {
      type === INPUT.TEXT ? (
        <Textarea
          readOnly={readOnly}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          invalid={invalid}
          overridden={overridden}
          onChange={e => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      ) : null
    }

    {
      type === INPUT.NUMBER ? (
        <Number
          readOnly={readOnly}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          invalid={invalid}
          overridden={overridden}
          onChange={e => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      ) : null
    }

    {
      type === INPUT.DROPDOWN || type === INPUT.DROPDOWN_MULTI || type === INPUT.HASH_SET ? (
        <Dropdown
          disableClear={disableClear}
          isMulti={type === INPUT.DROPDOWN_MULTI}
          isHashSet={type === INPUT.HASH_SET}
          selectProps={selectProps}
          readOnly={readOnly}
          usePortal={usePortal}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          invalid={invalid}
          overridden={overridden}
          options={options}
          onChange={e => {
            if (onChange) {
              onChange(e);
            }
          }}
        />
      ) : null
    }

    {
      type === INPUT.DATE || type === INPUT.DATE_RANGE ? (
        <DatePicker
          isDateRange={type === INPUT.DATE_RANGE}
          placeholder={placeholder}
          disableClear={disableClear}
          disabled={disabled}
          readOnly={readOnly}
          invalid={invalid}
          overridden={overridden}
          value={value}
          onChange={e => {
            if (onChange) {
              onChange(e);
            }
          }}
        />
      ) : null
    }

    {
      type === INPUT.SWITCH ? (
        <Switch
          disabled={disabled}
          readOnly={readOnly}
          invalid={invalid}
          overridden={overridden}
          value={value}
          onChange={e => {
            if (onChange) {
              onChange(e.checked);
            }
          }}
        />
      ) : null
    }
  </FieldWrapper>
);

export default FormField;
