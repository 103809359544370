import { useEffect, useMemo, useRef, useState } from 'react';
import cs from 'classnames';
import { ReactComponent as Logo } from '../../logo.svg';
import { ReactComponent as Burger } from '../../assets/burger.svg';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard.svg';
import { ReactComponent as FilingDraftIcon } from '../../assets/filing-drafts.svg';
import { ReactComponent as FilingIcon } from '../../assets/projects.svg';
import { ReactComponent as ObjectionIcon } from '../../assets/objections.svg';
import { ReactComponent as Library } from '../../assets/library.svg';
import { ReactComponent as Reporting } from '../../assets/reporting.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/project-analytics.svg';
import { ReactComponent as Organizer } from '../../assets/organizer.svg';
import { ReactComponent as Circular } from '../../assets/circular.svg';
import { ReactComponent as Admin } from '../../assets/settings.svg';
import { ReactComponent as Chevron } from '../../assets/chevron-down.svg';
import { ReactComponent as Drive } from '../../assets/drive.svg';
import { useLocalStorage, useWindowSize } from '../../helpers/hooks';
import { usePermissions } from '../../services/permissionsService';
import NavItem from './NavItem';
import SessionDebuggerWidget from '../SessionDebuggerWidget';
import './NavBar.scss';

const NavBar = ({
  navigate,
  location,
  user,
  userPreferences,
  hasWorkflow,
  hasRampDrive,
  hasFilingDrafts,
  hasAnalyticsFeature,
  hasCircularsFeature,
  unreadMentions,
}) => {
  const navBarContentRef = useRef(null);
  const { isAdmin, isAccountAdmin, hasPartialPermission, firstAccessibleUrl } = usePermissions();
  const windowWidth = useWindowSize();
  const isMobile = windowWidth < 768;

  const DASHBOARD = hasPartialPermission('DASHBOARD') ? [{
    name: 'Dashboard',
    url: '/dashboard',
    icon: <DashboardIcon />,
  }] : [];

  const FILING_DRAFTS = hasFilingDrafts && hasPartialPermission('FILING_DRAFTS') ? [{
    name: 'Filing Drafts',
    url: '/filing-drafts',
    icon: <FilingDraftIcon />,
    unreadMentions: hasPartialPermission('FILING_DRAFTS_CHAT') && Object.keys(unreadMentions.filingDrafts).reduce(
      (acc, num) => acc + unreadMentions.filingDrafts[num],
      0,
    ),
  }] : [];

  const FILINGS = hasPartialPermission('FILINGS') ? [{
    name: 'Filings',
    url: '/products',
    icon: <FilingIcon />,
    activeStates: ['/products', '/project'],
  }] : [];

  const OBJECTIONS = hasWorkflow && hasPartialPermission('OBJECTIONS') ? [{
    name: 'Objections',
    url: '/objections',
    icon: <ObjectionIcon />,
    activeStates: ['/objections', '/objections/dashboard'],
    unreadMentions: hasPartialPermission('OBJECTIONS_CHAT') && Object.keys(unreadMentions.objections).reduce(
      (acc, num) => acc + unreadMentions.objections[num],
      0,
    ),
  }] : [];

  const libraryFormsUrl = useMemo(() => {
    if (userPreferences?.formsLibraryFilters) {
      return `/forms-library`;
    } else {
      return '/forms-library?archived=false';
    }
  }, [userPreferences?.formsLibraryFilters]);

  const libraryRatesUrl = useMemo(() => {
    if (userPreferences?.ratesLibraryFilters) {
      return `/rates-library`;
    } else {
      return '/rates-library?archived=false';
    }
  }, [userPreferences?.ratesLibraryFilters]);

  const LIBRARY = hasPartialPermission('LIBRARY') ? [{
    name: 'Library',
    icon: <Library />,
    sub: [
      {
        name: 'Forms',
        url: libraryFormsUrl,
        activeStates: ['/forms-library'],
      },
      {
        name: 'Rates/Rules',
        url: libraryRatesUrl,
        activeStates: ['/rates-library'],
      },
    ],
  }] : [];

  const DRIVE = hasRampDrive && hasPartialPermission('DRIVE') ? [{
    name: 'Drive',
    icon: <Drive />,
    url: '/drive',
    activeStates: ['/drive'],
  }] : [];

  const REPORTING = hasPartialPermission('REPORTING') ? [{
    name: 'Reporting',
    icon: <Reporting />,
    sub: [
      {
        name: 'Filings',
        url: '/reporting/filings',
        activeStates: ['/reporting/filings']
      },
      {
        name: 'Projects',
        url: 'reporting/projects?archived=false&offset=0',
        activeStates: ['/reporting/projects']
      },
    ]
  }] : [];

  const ANALYTICS = hasAnalyticsFeature && hasPartialPermission('ANALYTICS') ? [{
    name: 'Analytics',
    icon: <AnalyticsIcon />,
    url: '/analytics',
    activeStates: ['/analytics'],
  }] : [];

  const ORGANIZER = hasPartialPermission('ORGANIZER') ? [{
    name: 'Organizer',
    icon: <Organizer />,
    url: '/organizer',
    hidden: isMobile,
  }] : [];

  const CIRCULARS = hasCircularsFeature ? [{
    name: 'Circulars',
    icon: <Circular />,
    url: '/circulars',
    hidden: isMobile,
  }] : [];

  const ADMIN = (isAdmin || isAccountAdmin) ? [{
    name: 'Admin',
    icon: <Admin />,
    url: '/admin',
    activeStates: ['/admin'],
  }] : [];

  const navigation = [
    ...DASHBOARD,
    ...FILING_DRAFTS,
    ...FILINGS,
    ...OBJECTIONS,
    ...LIBRARY,
    ...DRIVE,
    ...REPORTING,
    ...ANALYTICS,
    ...ORGANIZER,
    ...CIRCULARS,
    ...ADMIN,
  ];

  const [collapsed, setCollapsed] = useLocalStorage(
    'nav-bar-collapsed',
    'false',
  );
  const isTablet = windowWidth < 1024 && windowWidth >= 768;
  const [navbarContentOpened, setNavbarContentOpened] = useState(true);
  const isCollapsed = (collapsed === 'true' && !isMobile) || isTablet;

  useEffect(() => {
    setNavbarContentOpened(!isMobile);
  }, [isMobile]);

  const navBarClasses = cs('nav-bar-wrapper', {
    'nav-bar-wrapper__mobile': isMobile,
    'nav-bar-wrapper__mobile_active': isMobile && navbarContentOpened,
    'nav-bar-wrapper__expanded':
      !isMobile && !isTablet && collapsed === 'false',
  });

  const navBarToggleClasses = cs('nav-bar-toggle', {
    'nav-bar-toggle__collapsed': collapsed === 'true' || isTablet,
  });

  const onBurgerClick = () => {
    if (isMobile) {
      setNavbarContentOpened(!navbarContentOpened);
    }
  };

  const onNavigateHome = () => {
    navigate(firstAccessibleUrl);
  }

  const navBarHeading = (
    <div className="nav-bar-heading">
      {
        isMobile ? (
          <Burger className="nav-bar-burger" onClick={onBurgerClick} />
        ) : null
      }
      <Logo className="nav-bar-logo" onClick={onNavigateHome} />
    </div>
  );

  const navBarContent = navbarContentOpened ? (
    <div className="nav-bar-body" ref={navBarContentRef}>
      <div className="nav-bar-account">
        {
          (collapsed === 'false' || isMobile) && !isTablet ? (
            <span>{ user?.accountId?.name }</span>
          ) : null
        }
        {
          !isMobile ? (
            <Chevron
              className={navBarToggleClasses}
              onClick={() => setCollapsed(collapsed === 'false' ? 'true' : 'false')}
            />
          ) : null
        }
      </div>
      <div className="nav-bar-content">
        {
          navigation.map((item) => {
            if (item.hidden) {
              return null;
            }

            return (
              <NavItem
                key={item.name}
                item={item}
                collapsed={isCollapsed}
                location={location.pathname}
                onClick={(url) => navigate(url)}
              />
            );
          })
        }
      </div>
    </div>
  ) : null;

  return (
    <div className={navBarClasses}>
      { navBarHeading }
      { navBarContent }
      <SessionDebuggerWidget isExpanded={!isCollapsed} />
    </div>
  );
};

export default NavBar;
