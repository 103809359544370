import ReactGA from 'react-ga';
import { useState, useEffect, Suspense } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { notification, ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider } from 'next-themes';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import { getUsers } from './actions/usersActions';
import { getCompanies } from './actions/companiesActions';
import { useInterval, useNetwork, withRouter } from './helpers/hooks';
import { AUTHENTICATION_SUCCESS, getCurrentUser } from './actions/authActions';
import RoutesList from './RoutesList';
import {
  authenticateChat,
  initializeSocket,
  initializeChatListeners,
} from './actions/chatActions';
import { Toaster } from 'chakra/snippets/toaster';
import FallbackLoader from './components/FallbackLoader';
import ErrorBoundary from './general/ErrorBoundary/ErrorBoundary';
import ScrollHandler from './components/ScrollHandler';
import Layout from './components/Layout';
import ThemeConfig from './theme';
import './styles/index.scss';

let composer = compose;

if (
  typeof window !== 'undefined'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const createStoreWithMiddleware = composer(applyMiddleware(reduxThunk))(
  createStore,
);
const store = createStoreWithMiddleware(reducers);

const initSessionDebugger = (user) => {
  window.mpSessionDebuggerMetadata = {
    userId: user.username,
    userName: `${user.firstName || ''} ${user.lastName || ''}`,
  };
};

const checkAuth = (navigate, location, callBack) => {
  const token = localStorage.getItem('token');

  if (token) {
    store.dispatch(
      getCurrentUser(
        (currentUser) => {
          initSessionDebugger(currentUser);
          store.dispatch({ type: AUTHENTICATION_SUCCESS, payload: !!token });
          store.dispatch(getUsers());
          store.dispatch(getCompanies());
          store.dispatch(initializeSocket());
          store.dispatch(authenticateChat());
          store.dispatch(initializeChatListeners(navigate, location));
          callBack(true);
        },
        () => {
          if (
            window.location.pathname !== '/login'
            && window.location.pathname !== '/'
          ) {
            window.location.href = `/login?redirect=${window.location.pathname}`;
          }
          callBack(false);
        },
      ),
    );
  } else {
    callBack(false);
  }
};

if (process.env.REACT_APP_ENABLE_GA === 'true') {
  ReactGA.initialize('UA-86607-10');
}

const AppRouter = ({ location, navigate }) => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_GA === 'true') {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    // TODO should have location as a listener
    checkAuth(navigate, location, (isAuthenticated) => {
      setIsAuthChecked(isAuthenticated);
      setIsLoading(false);
    });
  }, []);

  useNetwork((e) => {
    if (e === 'OFFLINE') {
      notification.warning({
        key: 'connection-status',
        message: 'Connection lost',
        description:
          'Please check your internet connection and refresh the page',
        duration: 0,
      });
    }

    if (e === 'ONLINE') {
      notification.success({
        key: 'connection-status',
        message: 'Back Online',
        description: 'Internet connection established',
      });
    }
  });

  useInterval(() => {
    const issuedAt = localStorage.getItem('issuedAt');
    const expiresIn = localStorage.getItem('expiresIn');
    const time = Date.now();
    const delay = 12000;

    if (issuedAt && expiresIn) {
      const issuedAtTime = new Date(issuedAt).getTime();
      const expiresInTime = parseInt(expiresIn, 10) * 1000;

      if (time >= issuedAtTime + expiresInTime - delay) {
        localStorage.clear();
        document.location = '/login';
      }
    }
  }, 10000);

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <ChakraProvider value={ThemeConfig}>
      <ThemeProvider
        defaultTheme="light"
        attribute="class"
        disableTransitionOnChange
      >
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Inter, sans-serif'
            }
          }}
        >
          <ErrorBoundary>
            <Provider store={store} stabilityCheck="never">
              <ScrollHandler />
              <Toaster />
              <Layout location={location}>
                <Suspense fallback={<FallbackLoader />}>
                  <RoutesList isAuthChecked={isAuthChecked} />
                </Suspense>
              </Layout>
            </Provider>
          </ErrorBoundary>
        </ConfigProvider>
      </ThemeProvider>
    </ChakraProvider>
  );
};

export default withRouter(AppRouter);
