import { Switch as SwitchSnippet } from 'chakra/snippets/switch';

const Switch = ({
  disabled,
  readOnly,
  invalid,
  overridden,
  value,
  onChange,
}) => (
  <SwitchSnippet
    {
      ...overridden && {
        borderWidth: '2px',
        borderColor: 'primary',
        borderRadius: '30px',
        padding: '2px',
      }
    }
    readOnly={readOnly}
    disabled={disabled}
    invalid={invalid}
    checked={value}
    onCheckedChange={onChange}
    colorPalette="cyan"
  />
);

export default Switch;
