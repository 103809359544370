import { useCallback, useMemo, useState } from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';

const { RangePicker } = AntdDatePicker;

export const getDateValue = (value) => {
  if (!value) {
    return [];
  }

  const [start, end] = value.split(' ↔ ');

  if (start && end) {
    return [dayjs(start), dayjs(end)];
  }

  return [];
};

const DatePicker = ({
  isDateRange = false,
  placeholder,
  disableClear = false,
  disabled,
  readOnly,
  invalid,
  overridden,
  value,
  onChange,
}) => {
  const [focused, setFocused] = useState(false);
  const DateComponent = useMemo(() => (isDateRange ? RangePicker : AntdDatePicker), [
    isDateRange,
  ]);

  const onDateSelect = useCallback((_, dateString) => {
    if (isDateRange) {
      if (!_) {
        onChange(null);
        return;
      }
      onChange(dateString);
      return;
    }

    if (!dateString) {
      onChange(null);
      return;
    }
    onChange(dateString);
  }, [isDateRange, onChange]);

  const dateValue = useMemo(() => {
    if (!value) {
      return null;
    }

    if (isDateRange) {
      return getDateValue(value);
    }

    const date = value.split('T');

    if (!date) {
      return null;
    }

    return dayjs(date[0]);
  }, [value, isDateRange]);

  return (
    <Box
      w="full"
      h="40px"
      border="1px solid #e4e4e7"
      borderRadius="4px"
      backgroundColor="#fff"
      {
        ...(disabled || readOnly) && {
          backgroundColor: '#f9f9fa',
          border: 'none',
        }
      }
      {
        ...focused && {
          outline: '1px solid #0a91b1',
          border: '1px solid #0a91b1',
        }
      }
      {
        ...invalid && {
          border: '1px solid #ef4444',
          ...focused && {
            outline: '1px solid #ef4444',
          },
        }
      }
      {
        ...overridden && {
          borderWidth: '1px',
          borderColor: 'primary',
          outline: '1px solid #0a91b1',
          backgroundColor: '#f1f7f9',
        }
      }
    >
      <DateComponent
        inputReadOnly
        showToday={false}
        variant="borderless"
        disabled={disabled || readOnly}
        placeholder={placeholder}
        format="MM/DD/YYYY"
        value={dateValue}
        allowClear={!disableClear}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={onDateSelect}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: 'inherit',
          backgroundColor: 'inherit',
        }}
        popupStyle={{
          zIndex: 3000,
        }}
      />
    </Box>
  );
};

export default DatePicker;
