import { useMemo } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

const generateStyles = ({
  invalid,
  overridden,
}) => ({
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    paddingLeft: '8px',
    paddingRight: '8px',
  }),
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    cursor: 'pointer',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    paddingLeft: '8px',
    paddingRight: '8px',
    ...state.isDisabled && {
      color: '#b8b8bc',
    },
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    paddingLeft: '8px',
    paddingRight: '8px',
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: overridden ? '#fff' : '#f4f4f5',
    paddingLeft: '2px',
    border: '1px solid #e4e4e7',
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    fontSize: '13px',
  }),
  multiValueRemove: (baseStyles) => ({
    ...baseStyles,
    color: '#848484',
    cursor: 'pointer',
  }),
  noOptionsMessage: (baseStyles) => ({
    ...baseStyles,
    fontSize: '14px',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menu: (base) => ({
    ...base,
    animation: 'scale-fade-in 0.1s ease-out',
    '@keyframes scale-fade-in': {
      '0%': { transform: 'scale(0.95)', opacity: 0 },
      '100%': { transform: 'scale(1)', opacity: 1 },
    },
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    width: '100%',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '14px',
    backgroundColor: state.isFocused ? '#d7ecf1' : '#fff',
    color: 'black',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    ...state.isDisabled && {
      backgroundColor: '#f9f9fa',
      border: '1px solid #f9f9fa',
    },
    borderColor: '#e4e4e7',
    ...state.isFocused && {
      boxShadow: '0 0 0 1px #0a91b1',
      borderColor: '#0a91b1',
    },
    '&:hover': {
      ...invalid && {
        borderColor: '#ef4444',
        ...state.isFocused && {
          boxShadow: '0 0 0 1px #ef4444',
        },
      },
      ...overridden && {
        borderColor: '#0a91b1',
        boxShadow: '0 0 0 1px #0a91b1',
      },
    },
    fontSize: '14px',
    ...invalid && {
      borderColor: '#ef4444',
      ...state.isFocused && {
        boxShadow: '0 0 0 1px #ef4444',
      },
    },
    ...overridden && {
      borderColor: '#0a91b1',
      backgroundColor: '#f1f7f9',
      boxShadow: '0 0 0 1px #0a91b1',
    },
  }),
});

const Dropdown = ({
  options,
  overridden,
  readOnly,
  placeholder,
  disabled,
  value,
  onChange,
  invalid,
  isMulti,
  isHashSet,
  disableClear,
  usePortal,
  selectProps = {},
}) => {
  const styles = useMemo(() => generateStyles({
    invalid,
    overridden,
  }), [
    invalid,
    overridden,
  ]);

  if (isHashSet) {
    return (
      <Creatable
        isMulti
        isClearable={!disableClear}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isDisabled={disabled || readOnly}
        styles={styles}
        {
          ...!options.length && {
            noOptionsMessage: () => null,
            components: {
              Menu: () => null,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            },
          }
        }
        {
          ...usePortal && {
            menuPortalTarget: document.body,
          }
        }
      />
    );
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      isClearable={!disableClear}
      placeholder={placeholder}
      options={options}
      isDisabled={disabled || readOnly}
      styles={styles}
      {
        ...selectProps
      }
      {
        ...usePortal && {
          menuPortalTarget: document.body,
        }
      }
    />
  );
};

export default Dropdown;
