import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from './hooks';
import { currentUser, hasCircularsFeature } from '../selectors/selectors';
import { usePermissions } from '../services/permissionsService';

export default function (Component) {
  const RequireCircularsFeature = ({ hasCirculars, user, navigate }) => {
    const { firstAccessibleUrl } = usePermissions();

    useEffect(() => {
      if (user !== undefined && !hasCirculars) {
        navigate(firstAccessibleUrl);
      }
    }, [user, hasCirculars, firstAccessibleUrl]);

    if (hasCirculars) {
      return (
        <Component />
      );
    }
  };

  const mapStateToProps = (state) => ({
    hasCirculars: hasCircularsFeature(state),
    user: currentUser(state),
  });

  return connect(mapStateToProps)(withRouter(RequireCircularsFeature));
}
