import FormField from './FormField';

export default FormField;

export const INPUT = {
  DROPDOWN: 'DROPDOWN',
  DROPDOWN_MULTI: 'DROPDOWN_MULTI',
  DATE: 'DATE',
  DATE_RANGE: 'DATE_RANGE',
  RADIO: 'RADIO',
  HASH_SET: 'HASH_SET',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  SWITCH: 'SWITCH',
};