import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import NavBar from '../NavBar';
import noLayoutRoutes from './noLayoutRoutes';
import './Layout.scss';

const MainLayout = ({ children, location }) => {
  const noLayout = noLayoutRoutes
    .filter((route) => useMatch(route)).length > 0;

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 't8nplu9n',
        hide_default_launcher:
          window.innerWidth < 768 || location.pathname !== '/dashboard',
      });
    }
  }, [location.pathname]);

  return (
    <>
      {
        noLayout ? (
          <div key={location.pathname}>
            { children }
          </div>
        ) : (
          <div className="main-layout">
            <NavBar />
            <div
              className="main-content"
              key={location.pathname}
            >
              {children}
            </div>
          </div>
        )
      }
    </>
  );
};

export default MainLayout;
