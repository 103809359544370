import {
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER,
  EDIT_CURRENT_USER_PREFERENCES,
} from '../actions';

import {
  EDIT_PINNED_PROJECT,
} from '../actions/projectsActions';

import { RESET_CURRENT_USER } from '../actions/authActions';
import {
  UPDATE_CURRENT_USER_ACCOUNT_FEATURES,
  UPDATE_CURRENT_USER_ACCOUNT,
  UPDATE_CURRENT_USER_ACCOUNT_SAML_CONFIG,
  UPDATE_CURRENT_USER_ACCOUNT_PROJECT_NUMBER_GENERATOR_SETTINGS,
  UPDATE_CURRENT_USER_ACCOUNT_AI_SETTINGS,
} from '../actions/accountActions';

export default function (state = {
  isLoading: false,
  data: null,
}, action) {
  switch (action.type) {
    case GET_CURRENT_USER: {
      return {
        ...state,
        isLoading: true,
        data: null,
      };
    }
    case GET_CURRENT_USER_SUCCESS: {
      return {
        isLoading: false,
        data: action.payload,
      };
    }
    case UPDATE_CURRENT_USER_ACCOUNT_FEATURES: {
      return {
        isLoading: false,
        data: {
          ...state.data,
          accountId: {
            ...state.data.accountId,
            features: action.payload,
          },
        },
      };
    }
    case UPDATE_CURRENT_USER_ACCOUNT_PROJECT_NUMBER_GENERATOR_SETTINGS: {
      return {
        isLoading: false,
        data: {
          ...state.data,
          accountId: {
            ...state.data.accountId,
            projectNumberGeneratorSettings: action.payload,
          },
        },
      };
    }
    case RESET_CURRENT_USER: {
      return {
        isLoading: false,
        data: null,
      };
    }
    case EDIT_PINNED_PROJECT: {
      const pinnedProject = action.payload;
      return {
        isLoading: false,
        data: {
          ...state.data,
          pinnedProject,
        },
      };
    }
    case UPDATE_CURRENT_USER_ACCOUNT: {
      return {
        isLoading: false,
        data: {
          ...state.data,
          accountId: action.payload,
        },
      };
    }
    case UPDATE_CURRENT_USER_ACCOUNT_SAML_CONFIG: {
      return {
        isLoading: false,
        data: {
          ...state.data,
          accountId: {
            ...state.data.accountId,
            auth: {
              ...state.data.accountId.auth,
              saml: action.payload,
            },
          },
        },
      };
    }
    case EDIT_CURRENT_USER_PREFERENCES: {
      return {
        isLoading: false,
        data: {
          ...state.data,
          preferences: {
            ...state.data.preferences,
            ...state.data.preferences && {
              ui: action.payload,
            },
          },
        },
      };
    }
    case UPDATE_CURRENT_USER_ACCOUNT_AI_SETTINGS: {
      return {
        isLoading: false,
        data: {
          ...state.data,
          accountId: {
            ...state.data.accountId,
            aiSettings: action.payload,
          }
        },
      };
    }
    default:
      return state;
  }
}
