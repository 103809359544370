import { useState } from 'react';
import {
  HStack,
  Separator,
  Card,
  Text,
  Badge,
  Flex,
  Box,
  Stack,
} from '@chakra-ui/react';
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from 'chakra/snippets/popover';
import {
  Radio,
  RadioGroup,
} from 'chakra/snippets/radio';
import {
  LuList,
  LuRefreshCcw,
  LuTrash2,
  LuDownload,
  LuCopy,
  LuArchive,
  LuPencil,
  LuCalendarCheck2,
  LuCalendarClock,
  LuPlus,
  LuClipboardList,
  LuArrowLeftRight,
  LuEllipsisVertical,
  LuListCheck,
  LuTableProperties,
} from 'react-icons/lu';
import {
  SegmentedControl,
} from 'chakra/snippets/segmented-control';
import {
  Button,
} from 'chakra/snippets/button';
import { ConfirmDialog } from 'chakra/DialogConfirm';
import FormField, { INPUT } from 'chakra/FormField';
import Dialog from 'chakra/Dialog';
import Drawer from 'chakra/Drawer';
import Menu from 'chakra/Menu';
import FilterBar from 'chakra/FilterBar';
import AvatarGroup from 'chakra/AvatarGroup';
import AvatarSelector from 'chakra/AvatarSelector';
import Uploader from 'chakra/Uploader';
import Table, { TableColumnSelector } from 'chakra/Table';
import {
  STATE_LIST,
} from '../services/StateService';
import Attachment from 'chakra/Attachment';

const options = STATE_LIST;

const FILTERS = [
  {
    name: 'Is Archived',
    field: 'archived',
    type: INPUT.RADIO,
    tab: 'FILING_DETAILS',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'RAMP Product',
    field: 'productIds',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'FILING_DETAILS',
    options,
  },
  {
    name: 'RAMP Project',
    field: 'projectIds',
    tab: 'FILING_DETAILS',
    type: INPUT.DROPDOWN_MULTI,
    options,
  },
  {
    name: 'Is Assigned',
    field: 'isAssigned',
    type: INPUT.RADIO,
    tab: 'FILING_DETAILS',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'SERFF Product',
    field: 'productNames',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'SERFF Project',
    field: 'projectNames',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'SERFF Project Number',
    field: 'projectNumbers',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Business Type',
    field: 'businessTypes',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'State',
    field: 'states',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'FILING_DETAILS',
    options,
  },
  {
    name: 'Source',
    field: 'sources',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'FILING_DETAILS',
    options,
  },
  {
    name: 'Type Of Insurance',
    field: 'typeOfInsurances',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
    options,
  },
  {
    name: 'Sub-Type Of Insurance',
    field: 'subTypeOfInsurances',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
    options,
  },
  {
    name: 'Filing Type',
    field: 'filingTypes',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'FILING_DETAILS',
    options,
  },
  {
    name: 'RAMP Status',
    field: 'statusIds',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'FILING_DETAILS',
    options,
  },
  {
    name: 'SERFF Status',
    field: 'statuses',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Tracking Number',
    field: 'trackingNumbers',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Custom Tracking Number',
    field: 'customTrackingNumbers',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Companies',
    field: 'companies',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Authors',
    field: 'authors',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Effective Date Requested (New) - Upon Approval',
    field: 'generalInformation.effectiveDateRequestedNewUponApproval',
    type: INPUT.RADIO,
    tab: 'GENERAL_INFORMATION',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'Effective Date Requested (Renewal) - Upon Approval',
    field: 'generalInformation.effectiveDateRequestedRenewalUponApproval',
    type: INPUT.RADIO,
    tab: 'GENERAL_INFORMATION',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'Corresponding Tracking Number',
    field: 'generalInformation.correspondingTrackingNumbers',
    type: INPUT.HASH_SET,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Effective Date Requested (New)',
    field: 'generalInformation.effectiveDateRequestedNew',
    type: INPUT.DATE,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Effective Date Requested (Renewal)',
    field: 'generalInformation.effectiveDateRequestedRenewal',
    type: INPUT.DATE,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Reference Title',
    field: 'generalInformation.referenceTitles',
    type: INPUT.HASH_SET,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Reference Organization',
    field: 'generalInformation.referenceOrganizations',
    type: INPUT.HASH_SET,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Reference Number',
    field: 'generalInformation.referenceNumbers',
    type: INPUT.HASH_SET,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Domicile Status',
    field: 'generalInformation.domicileStatuses',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'GENERAL_INFORMATION',
    options,
  },
  {
    name: 'Domicile Status Comment',
    field: 'generalInformation.domicileStatusComments',
    type: INPUT.HASH_SET,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Advisory Organization Circular',
    field: 'generalInformation.advisoryOrganizationCirculars',
    type: INPUT.HASH_SET,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Company Tracking Number',
    field: 'generalInformation.companyTrackingNumbers',
    type: INPUT.HASH_SET,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Filing Description',
    field: 'generalInformation.descriptions',
    type: INPUT.HASH_SET,
    tab: 'GENERAL_INFORMATION',
  },
  {
    name: 'Reviewers',
    field: 'reviewers',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Primary Reviewers',
    field: 'primaryReviewers',
    type: INPUT.HASH_SET,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Date Submitted',
    field: 'dateSubmitted',
    type: INPUT.DATE,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Created At',
    field: 'createdAt',
    type: INPUT.DATE,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Updated At',
    field: 'updatedAt',
    type: INPUT.DATE,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Disposition Date',
    field: 'disposition.dispositionDate',
    type: INPUT.DATE,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Effective Implementation Date (New)',
    field: 'disposition.effectiveImplementationDateNew',
    type: INPUT.DATE,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Effective Implementation Date (Renewal)',
    field: 'disposition.effectiveImplementationDateRenewal',
    type: INPUT.DATE,
    tab: 'FILING_DETAILS',
  },
  {
    name: 'Has Previous Version',
    field: 'form.hasPreviousVersion',
    type: INPUT.RADIO,
    tab: 'FORMS',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'Is Confidential?',
    field: 'form.confidential',
    type: INPUT.RADIO,
    tab: 'FORMS',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'Is Public?',
    field: 'form.publicAccess',
    type: INPUT.RADIO,
    tab: 'FORMS',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'Form Name',
    field: 'form.formNames',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Form Number',
    field: 'form.formNumbers',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Action',
    field: 'form.actions',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'FORMS',
    options,
  },
  {
    name: 'Form Type',
    field: 'form.formTypes',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'FORMS',
    options,
  },
  {
    name: 'Edition Date',
    field: 'form.editionDates',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Review Status',
    field: 'form.reviewStatuses',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Other Explanation',
    field: 'form.otherExplanations',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Lead Form Number',
    field: 'form.leadFormNumbers',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Created By',
    field: 'form.usersCreated',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Submitted By',
    field: 'form.usersSubmitted',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Last Updated By',
    field: 'form.usersLastModified',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Previous Filing Number',
    field: 'form.previousFilingNumbers',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Replaced Form Number',
    field: 'form.replacedFormNumbers',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Replaced Edition Date',
    field: 'form.replacedEditionDates',
    type: INPUT.HASH_SET,
    tab: 'FORMS',
  },
  {
    name: 'Date Created',
    field: 'form.dateCreated',
    type: INPUT.DATE,
    tab: 'FORMS',
  },
  {
    name: 'Date Last Modified',
    field: 'form.dateLastModified',
    type: INPUT.DATE,
    tab: 'FORMS',
  },
  {
    name: 'Date Submitted',
    field: 'form.dateSubmitted',
    type: INPUT.DATE,
    tab: 'FORMS',
  },
  {
    name: 'Has Previous Version',
    field: 'rate.hasPreviousVersion',
    type: INPUT.RADIO,
    tab: 'RATES',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'Is Confidential?',
    field: 'rate.confidential',
    type: INPUT.RADIO,
    tab: 'RATES',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'Is Public?',
    field: 'rate.publicAccess',
    type: INPUT.RADIO,
    tab: 'RATES',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'Rate Name',
    field: 'rate.rateNames',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Rule Number',
    field: 'rate.ruleNumbers',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Action',
    field: 'rate.actions',
    type: INPUT.DROPDOWN_MULTI,
    tab: 'RATES',
    options,
  },
  {
    name: 'Review Status',
    field: 'rate.reviewStatuses',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Created By',
    field: 'rate.usersCreated',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Submitted By',
    field: 'rate.usersSubmitted',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Last Updated By',
    field: 'rate.usersLastModified',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Previous Filing Number',
    field: 'rate.previousFilingNumbers',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Affected Form Numbers',
    field: 'rate.affectedFormNumbers',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Other Explanation',
    field: 'rate.otherExplanations',
    type: INPUT.HASH_SET,
    tab: 'RATES',
  },
  {
    name: 'Date Created',
    field: 'rate.dateCreated',
    type: INPUT.DATE,
    tab: 'RATES',
  },
  {
    name: 'Date Last Modified',
    field: 'rate.dateLastModified',
    type: INPUT.DATE,
    tab: 'RATES',
  },
  {
    name: 'Date Submitted',
    field: 'rate.dateSubmitted',
    type: INPUT.DATE,
    tab: 'RATES',
  },
];

const USERS = [
  {
    _id: '1',
    name: 'Spartak Poghosyan',
    color: '#2c48bb',
  },
  {
    _id: '2',
    name: 'Jeremy Battles',
    color: '#ff5318',
  },
  {
    _id: '3',
    name: 'Dominic Kopec',
    color: '#4b5b66',
  },
  {
    _id: '4',
    name: 'Ava Larson',
    color: '#d91b5b',
  },
  {
    _id: '5',
    name: 'Sophia Miller',
    color: '#1b998b',
  },
  {
    _id: '6',
    name: 'Liam Johnson',
    color: '#f4d35e',
  },
  {
    _id: '7',
    name: 'Olivia Brown',
    color: '#6a0572',
  },
  {
    _id: '8',
    name: 'Noah Wilson',
    color: '#0468bf',
  },
  {
    _id: '9',
    name: 'Emma Garcia',
    color: '#f45d01',
  },
  {
    _id: '10',
    name: 'Lucas Davis',
    color: '#27a844',
  },
  {
    _id: '11',
    name: 'Isabella Martinez',
    color: '#ffcb47',
  },
  {
    _id: '12',
    name: 'Mason Anderson',
    color: '#3b3b98',
  },
  {
    _id: '13',
    name: 'Mia Taylor',
    color: '#e63946',
  },
  {
    _id: '14',
    name: 'Ethan Thomas',
    color: '#ffc300',
  },
  {
    _id: '15',
    name: 'Charlotte Moore',
    color: '#4a7c59',
  },
  {
    _id: '16',
    name: 'James Lee',
    color: '#cb4a43',
  },
  {
    _id: '17',
    name: 'Amelia White',
    color: '#34626c',
  },
  {
    _id: '18',
    name: 'Elijah Harris',
    color: '#ffaa33',
  },
  {
    _id: '19',
    name: 'Harper Martin',
    color: '#5d4e60',
  },
  {
    _id: '20',
    name: 'Alexander Young',
    color: '#1e81b0',
  },
];

const columns = [
  {
    key: 'name',
    title: 'Name',
    width: 110,
    fixed: 'left',
  },
  {
    key: 'age',
    title: 'Age',
    width: 100,
    sortable: true,
    cell: (age) => (
      <Badge variant="surface">{ age }</Badge>
    ),
  },
  {
    key: 'email',
    title: 'Email',
    width: 300,
    sortable: true,
    cell: (email) => (
      <Badge variant="surface" colorPalette="cyan">{ email }</Badge>
    ),
  },
  {
    key: 'address',
    title: 'Address',
    width: 400,
    sortable: true,
  },
  {
    key: 'phone',
    title: 'Phone',
    width: 200,
    sortable: true,
  },
  {
    key: 'actions',
    title: 'Actions',
    width: 100,
    fixed: 'right',
    cell: () => (
      <Flex flex={1} justifyContent="flex-end">
        <Menu
          trigger={(
            <Button
              p={1}
              variant="ghost"
              size="sm"
              _hover={{ bg: 'gray.200' }}
            >
              <LuEllipsisVertical />
            </Button>
            )}
          options={[
            {
              label: 'Edit',
              value: 'edit',
              icon: <LuPencil />,
            },
            {
              label: 'Clone',
              value: 'clone',
              icon: <LuCopy />,
            },
            {
              label: 'Replaces',
              value: 'replaces',
              icon: <LuArrowLeftRight />,
              separator: true,
            },
            {
              label: 'Archive',
              value: 'Archive',
              icon: <LuArchive />,
              danger: true,
            },
          ]}
        />
      </Flex>
    ),
  },
];

const data = [
  {
    _id: 1, name: 'Bob', age: 24, email: 'bob1@example.com', address: '313 Chestnut St, Coast City', phone: '365-826-4601',
  },
  {
    _id: 2,
    name: 'Eve',
    age: 59,
    email: 'eve2@example.com',
    address: '392 Elm St, Hill Valley',
    phone: '213-706-7485',
    children: [
      {
        _id: 1112,
        name: 'Eve Child 3',
        age: 59,
        email: 'eve2@example.com',
        address: '392 Elm St, Hill Valley',
        phone: '213-706-7485',
      },
      {
        _id: 1113,
        name: 'Eve Child 4',
        age: 12,
        email: 'eve2@example.com',
        address: '392 Elm St, Hill Valley',
        phone: '213-706-7485',
      },
    ],
  },
  {
    _id: 3,
    name: 'Hank',
    age: 28,
    email: 'hank3@example.com',
    address: '447 Chestnut St, Springfield',
    phone: '286-531-6898',
    children: [
      {
        _id: 2212,
        name: 'Eve Child 1',
        age: 59,
        email: 'eve2@example.com',
        address: '392 Elm St, Hill Valley',
        phone: '213-706-7485',
      },
      {
        _id: 2313,
        name: 'Eve Child 2',
        age: 12,
        email: 'eve2@example.com',
        address: '392 Elm St, Hill Valley',
        phone: '213-706-7485',
      },
    ],
  },
  {
    _id: 4, name: 'Eve', age: 29, email: 'eve4@example.com', address: '130 Oak St, Star City', phone: '991-654-6459',
  },
  {
    _id: 5, name: 'Alice', age: 35, email: 'alice5@example.com', address: '510 Willow St, Central City', phone: '292-579-4780',
  },
  {
    _id: 6, name: 'Alice', age: 58, email: 'alice6@example.com', address: '108 Chestnut St, Gotham', phone: '198-213-4358',
  },
  {
    _id: 7, name: 'Eve', age: 20, email: 'eve7@example.com', address: '224 Main St, Star City', phone: '224-502-3758',
  },
  {
    _id: 8, name: 'Tina', age: 52, email: 'tina8@example.com', address: '677 Main St, Star City', phone: '918-458-8720',
  },
  {
    _id: 9, name: 'Alice', age: 44, email: 'alice9@example.com', address: '414 Oak St, Springfield', phone: '337-707-3980',
  },
  {
    _id: 10, name: 'Grace', age: 33, email: 'grace10@example.com', address: '134 Chestnut St, Hill Valley', phone: '193-811-1649',
  },
  {
    _id: 11, name: 'Eve', age: 30, email: 'eve11@example.com', address: '419 Cedar St, Hill Valley', phone: '444-169-2404',
  },
  {
    _id: 12, name: 'Steve', age: 39, email: 'steve12@example.com', address: '681 Main St, Bludhaven', phone: '471-819-7810',
  },
  {
    _id: 13, name: 'Karen', age: 49, email: 'karen13@example.com', address: '364 Elm St, Hill Valley', phone: '696-842-5249',
  },
  {
    _id: 14, name: 'Leo', age: 32, email: 'leo14@example.com', address: '549 Cedar St, Riverdale', phone: '613-707-6857',
  },
  {
    _id: 15, name: 'Alice', age: 38, email: 'alice15@example.com', address: '292 Elm St, Hill Valley', phone: '802-272-2486',
  },
  {
    _id: 16, name: 'Karen', age: 25, email: 'karen16@example.com', address: '730 Cedar St, Springfield', phone: '451-948-9149',
  },
  {
    _id: 17, name: 'Tina', age: 50, email: 'tina17@example.com', address: '862 Birch St, Riverdale', phone: '659-868-8652',
  },
  {
    _id: 18, name: 'Paul', age: 25, email: 'paul18@example.com', address: '261 Cedar St, Metropolis', phone: '371-903-7642',
  },
  {
    _id: 19, name: 'Olivia', age: 30, email: 'olivia19@example.com', address: '676 Walnut St, Smallville', phone: '865-383-5000',
  },
  {
    _id: 20, name: 'Alice', age: 49, email: 'alice20@example.com', address: '575 Walnut St, Star City', phone: '220-113-4202',
  },
  {
    _id: 21, name: 'Nate', age: 49, email: 'nate21@example.com', address: '343 Chestnut St, Star City', phone: '972-242-4275',
  },
  {
    _id: 22, name: 'Mona', age: 56, email: 'mona22@example.com', address: '604 Chestnut St, Star City', phone: '666-480-9743',
  },
  {
    _id: 23, name: 'Eve', age: 40, email: 'eve23@example.com', address: '947 Maple St, Bludhaven', phone: '953-324-9561',
  },
  {
    _id: 24, name: 'Bob', age: 35, email: 'bob24@example.com', address: '226 Oak St, Springfield', phone: '690-640-2559',
  },
  {
    _id: 25, name: 'Alice', age: 34, email: 'alice25@example.com', address: '275 Cedar St, Smallville', phone: '811-963-2386',
  },
  {
    _id: 26, name: 'Nate', age: 33, email: 'nate26@example.com', address: '814 Oak St, Bludhaven', phone: '296-914-3642',
  },
  {
    _id: 27, name: 'Leo', age: 60, email: 'leo27@example.com', address: '841 Main St, Bludhaven', phone: '410-792-3584',
  },
  {
    _id: 28, name: 'Nate', age: 52, email: 'nate28@example.com', address: '474 Cedar St, Smallville', phone: '804-309-3170',
  },
  {
    _id: 29, name: 'Hank', age: 35, email: 'hank29@example.com', address: '712 Oak St, Central City', phone: '695-505-7637',
  },
  {
    _id: 30, name: 'Eve', age: 25, email: 'eve30@example.com', address: '503 Walnut St, Central City', phone: '411-455-5851',
  },
  {
    _id: 31, name: 'Grace', age: 24, email: 'grace31@example.com', address: '494 Chestnut St, Star City', phone: '979-399-3332',
  },
  {
    _id: 32, name: 'Quincy', age: 38, email: 'quincy32@example.com', address: '834 Willow St, Coast City', phone: '258-638-1660',
  },
  {
    _id: 33, name: 'Paul', age: 22, email: 'paul33@example.com', address: '685 Cedar St, Springfield', phone: '923-327-1172',
  },
  {
    _id: 34, name: 'Leo', age: 26, email: 'leo34@example.com', address: '522 Walnut St, Star City', phone: '460-282-3593',
  },
  {
    _id: 100, name: 'Steve', age: 56, email: 'steve100@example.com', address: '154 Willow St, Springfield', phone: '122-736-8454',
  },
];

const Chakra = () => {
  const [chakraDialogOpen, setChakraDialogOpen] = useState(false);
  const [chakraDrawerOpen, setChakraDrawerOpen] = useState(false);
  const [tableView, setTableView] = useState('Simple');
  const [inputView, setInputView] = useState('Simple');
  const [sorting, setSorting] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });
  const [dynamicColumns, setDynamicColumns] = useState(columns.reduce((acc, num, index) => {
    acc[num.key] = {
      label: num.title,
      visible: true,
      order: index + 1,
    }
    return acc;
  }, {}));

  return (
    <Box p="15px 0">

      {/* Inputs */}
      <Card.Root variant="outline">
        <Card.Header>
          <Text textStyle="xl">Inputs</Text>
        </Card.Header>
        <Card.Body>
          <Box mb={4}>
            <SegmentedControl
              value={inputView}
              onValueChange={e => setInputView(e.value)}
              items={['Simple', 'Invalid', 'Inline', 'Disabled', 'Overridden']}
            />
          </Box>
          <HStack gap="6" marginBottom="10" w="full">
            {
              inputView === 'Simple' ? (
                <Stack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.STRING}
                        label="Email"
                        placeholder="Type"
                        helperText="Text input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.DATE}
                        label="Effective Date"
                        placeholder="Select Date"
                        helperText="Datepicker input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.DATE_RANGE}
                        label="Effective Dates"
                        placeholder="Select Date range"
                        helperText="Date range picker input"
                      />
                    </Flex>
                  </HStack >
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.DROPDOWN}
                        label="Product"
                        placeholder="Select"
                        options={options}
                        helperText="Single value dropdown"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.DROPDOWN_MULTI}
                        label="Project"
                        placeholder="Type and Enter..."
                        options={options}
                        helperText="Multiple value dropdown"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.HASH_SET}
                        label="Type Of Insurance"
                        placeholder="Type and Enter..."
                        options={options}
                        helperText="Hash-set input with dropdown options"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.NUMBER}
                        label="Readbility Score"
                        placeholder="Type"
                        helperText="Number input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.HASH_SET}
                        label="Companies"
                        placeholder="Type and Enter..."
                        helperText="Hash-set input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.SWITCH}
                        label="Confidential"
                        helperText="Switch input"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        type={INPUT.TEXT}
                        label="Description"
                        placeholder="Type"
                        helperText="Textarea input"
                      />
                    </Flex>
                  </HStack>
                </Stack>
              ) : null
            }
            {
              inputView === 'Invalid' ? (
                <Stack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.STRING}
                        label="Email"
                        placeholder="Type"
                        errorText="Text input required"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.DATE}
                        label="Effective Date"
                        placeholder="Select Date"
                        errorText="Datepicker input required"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.DATE_RANGE}
                        label="Effective Dates"
                        placeholder="Select Date"
                        errorText="Date range picker input required"
                      />
                    </Flex>
                  </HStack >
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.DROPDOWN}
                        label="Product"
                        placeholder="Select"
                        options={options}
                        errorText="Single value dropdown required"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.DROPDOWN_MULTI}
                        label="Project"
                        placeholder="Type and Enter..."
                        options={options}
                        errorText="Multiple value dropdown required"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.HASH_SET}
                        label="Type Of Insurance"
                        placeholder="Type and Enter..."
                        options={options}
                        errorText="Hash-set input with dropdown options required"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.NUMBER}
                        label="Readbility Score"
                        placeholder="Type"
                        errorText="Number input required"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.HASH_SET}
                        label="Companies"
                        placeholder="Type and Enter..."
                        errorText="Hash-set input required"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.SWITCH}
                        label="Confidential"
                        errorText="Switch input required"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        invalid
                        required
                        type={INPUT.TEXT}
                        label="Description"
                        placeholder="Type"
                        errorText="Textarea input required"
                      />
                    </Flex>
                  </HStack>
                </Stack>
              ) : null
            }
            {
              inputView === 'Disabled' ? (
                <Stack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.STRING}
                        label="Email"
                        placeholder="Type"
                        helperText="Text input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.DATE}
                        label="Effective Date"
                        placeholder="Select Date"
                        helperText="Datepicker input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.DATE_RANGE}
                        label="Effective Dates"
                        placeholder="Select Date"
                        helperText="Date range picker input"
                      />
                    </Flex>
                  </HStack >
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.DROPDOWN}
                        label="Product"
                        placeholder="Select"
                        options={options}
                        helperText="Single value dropdown"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.DROPDOWN_MULTI}
                        label="Project"
                        placeholder="Type and Enter..."
                        options={options}
                        helperText="Multiple value dropdown"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.HASH_SET}
                        label="Type Of Insurance"
                        placeholder="Type and Enter..."
                        options={options}
                        helperText="Hash-set input with dropdown options"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.NUMBER}
                        label="Readbility Score"
                        placeholder="Type"
                        helperText="Number input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.HASH_SET}
                        label="Companies"
                        placeholder="Type and Enter..."
                        helperText="Hash-set input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.SWITCH}
                        label="Confidential"
                        helperText="Switch input"
                      />
                    </Flex>
                  </HStack>

                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        disabled
                        type={INPUT.TEXT}
                        label="Description"
                        placeholder="Type"
                        helperText="Textarea input"
                      />
                    </Flex>
                  </HStack>
                </Stack>
              ) : null
            }
            {
              inputView === 'Overridden' ? (
                <Stack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.STRING}
                        label="Email"
                        placeholder="Type"
                        helperText="Text input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.DATE}
                        label="Effective Date"
                        placeholder="Select Date"
                        helperText="Datepicker input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.DATE_RANGE}
                        label="Effective Dates"
                        placeholder="Select Date"
                        helperText="Date range picker input"
                      />
                    </Flex>
                  </HStack >
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.DROPDOWN}
                        label="Product"
                        placeholder="Select"
                        options={options}
                        helperText="Single value dropdown"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.DROPDOWN_MULTI}
                        label="Project"
                        placeholder="Type and Enter..."
                        options={options}
                        helperText="Multiple value dropdown"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.HASH_SET}
                        label="Type Of Insurance"
                        placeholder="Type and Enter..."
                        options={options}
                        helperText="Hash-set input with dropdown options"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.NUMBER}
                        label="Readbility Score"
                        placeholder="Type"
                        helperText="Number input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.HASH_SET}
                        label="Companies"
                        placeholder="Type and Enter..."
                        helperText="Hash-set input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.SWITCH}
                        label="Confidential"
                        helperText="Switch input"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        overridden
                        type={INPUT.TEXT}
                        label="Description"
                        placeholder="Type"
                        helperText="Textarea input"
                      />
                    </Flex>
                  </HStack>
                </Stack>
              ) : null
            }
            {
              inputView === 'Inline' ? (
                <Stack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.STRING}
                        label="Email"
                        placeholder="Type"
                        helperText="Text input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.NUMBER}
                        label="Readbility Score"
                        placeholder="Type"
                        helperText="Number input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.DATE_RANGE}
                        label="Effective Dates"
                        placeholder="Select Date"
                        helperText="Date range picker input"
                      />
                    </Flex>
                  </HStack >
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.DROPDOWN}
                        label="Product"
                        placeholder="Select"
                        options={options}
                        helperText="Single value dropdown"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.DROPDOWN_MULTI}
                        label="Project"
                        placeholder="Type and Enter..."
                        options={options}
                        helperText="Multiple value dropdown"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.HASH_SET}
                        label="Type Of Insurance"
                        placeholder="Type and Enter..."
                        options={options}
                        helperText="Hash-set input with dropdown options"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.DATE}
                        label="Effective Date"
                        placeholder="Select Date"
                        helperText="Datepicker input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.HASH_SET}
                        label="Companies"
                        placeholder="Type and Enter..."
                        helperText="Hash-set input"
                      />
                    </Flex>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.SWITCH}
                        label="Confidential"
                        helperText="Switch input"
                      />
                    </Flex>
                  </HStack>
                  <HStack alignItems="flex-start" mb={4}>
                    <Flex w={300}>
                      <FormField
                        inline
                        type={INPUT.TEXT}
                        label="Description"
                        placeholder="Type"
                        helperText="Textarea input"
                      />
                    </Flex>
                  </HStack>
                </Stack>
              ) : null
            }
          </HStack>
        </Card.Body>
      </Card.Root>
      <Separator margin="10px 0" />

      {/* Table */}
      <Card.Root variant="outline">
        <Card.Header>
          <Text textStyle="xl">Table</Text>
        </Card.Header>
        <Card.Body>
          <Flex justifyContent="space-between" mb={4}>
            <SegmentedControl
              value={tableView}
              onValueChange={e => setTableView(e.value)}
              items={['Simple', 'Group By', 'Selectable', 'Expandable', 'Paginated', 'Loading', 'No Data']}
            />
            <TableColumnSelector
              columns={columns}
              onChange={() => {}}
              trigger={() => (
                <Button variant="outline">
                  <LuTableProperties />
                  Columns
                </Button>
              )}
            />
          </Flex>
          <HStack gap="6" marginBottom="10" w="full">
            {
              tableView === 'Simple' ? (
                <Table
                  data={data}
                  columns={columns}
                  sorting={sorting}
                  setSorting={setSorting}
                  height="700px"
                />
              ) : null
            }
            {
              tableView === 'Selectable' ? (
                <Table
                  data={data}
                  columns={columns}
                  height="700px"
                  sorting={sorting}
                  setSorting={setSorting}
                  selectedRows={selectedRows}
                  onRowSelect={setSelectedRows}
                />
              ) : null
            }
            {
              tableView === 'Expandable' ? (
                <Table
                  expandable
                  data={data}
                  columns={columns}
                  sorting={sorting}
                  setSorting={setSorting}
                  height="700px"
                  selectedRows={selectedRows}
                  onRowSelect={setSelectedRows}
                />
              ) : null
            }
            {
              tableView === 'Group By' ? (
                <Table
                  groupBy="name"
                  data={data}
                  columns={columns}
                  height="700px"
                />
              ) : null
            }
            {
              tableView === 'Paginated' ? (
                <Table
                  expandable
                  pagination={pagination}
                  setPagination={setPagination}
                  total={data.length}
                  data={data}
                  columns={columns}
                  sorting={sorting}
                  setSorting={setSorting}
                  height="700px"
                  selectedRows={selectedRows}
                  onRowSelect={setSelectedRows}
                />
              ) : null
            }
            {
              tableView === 'Loading' ? (
                <Table
                  loading
                  expandable
                  pagination={pagination}
                  setPagination={setPagination}
                  total={data.length}
                  data={data}
                  columns={columns}
                  sorting={sorting}
                  setSorting={setSorting}
                  height="700px"
                  selectedRows={selectedRows}
                  onRowSelect={setSelectedRows}
                />
              ) : null
            }
            {
              tableView === 'No Data' ? (
                <Table
                  expandable
                  pagination={pagination}
                  setPagination={setPagination}
                  data={[]}
                  columns={columns}
                  sorting={sorting}
                  setSorting={setSorting}
                  height="700px"
                  selectedRows={selectedRows}
                  onRowSelect={setSelectedRows}
                />
              ) : null
            }
          </HStack>
        </Card.Body>
      </Card.Root>
      <Separator margin="10px 0" />

      {/* Product/Project Heading */}
      <Card.Root variant="outline">
        <Card.Header>
          <Text textStyle="xl">Product/Project Heading</Text>
        </Card.Header>
        <Card.Body>
          <HStack
            w="380px"
            gap={3}
            p={3}
            marginBottom="10"
            borderWidth={1}
            borderRadius={2}
            borderColor="gray.200"
            position="relative"
            alignItems="flex-start"
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius={2}
              textStyle="sm"
              color="#fff"
              bg="#2d8665"
              w="50px"
              h="50px"
            >
              RRIC
            </Flex>
            <Stack gap={2} bg="#fff" w="calc(100% - 58px)">
              <Flex>
                <Text truncate textStyle="md" fontWeight="semibold">
                  Openly Homeowner Program Openly Homeowner Program
                </Text>
                <Badge ml={2} variant="solid" colorPalette="green">
                  Active
                </Badge>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text truncate textStyle="sm">
                  HO-A&D-20
                </Text>
                <LuPencil cursor="pointer" />
              </Flex>
            </Stack>
          </HStack>
        </Card.Body>
      </Card.Root>
      <Separator margin="10px 0" />

      {/* Filter Bar */}
      <Card.Root variant="outline">
        <Card.Header>
          <Text textStyle="xl">Filter Bar</Text>
        </Card.Header>
        <Card.Body>
          <HStack gap="6" marginBottom="10" w="full">
            <FilterBar
              filters={FILTERS}
              onSubmit={() => {}}
              onClear={() => {}}
              initialValues={{}}
            />
            <Button colorPalette="blue" size="sm">
              <LuPlus />
              Add Form
            </Button>
          </HStack>

          <HStack gap="6" marginBottom="10" w="full">
            <FilterBar
              initialValues={{}}
              filters={FILTERS.map(filter => {
                const { tab, ...rest } = filter;
                return rest;
              })}
              onSubmit={() => {}}
              onClear={() => {}}
            />
            <Button colorPalette="blue" size="sm">
              <LuPlus />
              Add Form
            </Button>
          </HStack>
        </Card.Body>
      </Card.Root>

      <Separator margin="10px 0" />

      {/* Uploader */}
      <Card.Root variant="outline">
        <Card.Header>
          <Text textStyle="xl">Uploader</Text>
        </Card.Header>
        <Card.Body>
          <HStack gap="6" marginBottom="10" w="full" alignItems="flex-start">
            <Box w="30%">
              <Uploader
                withDropZone
              />
            </Box>
            <Box w="30%">
              <Uploader
                onDownload={() => {}}
                onCompare={() => {}}
                state={{
                  '6798cc55a962cb0013517764': {
                    downloading: true,
                  },
                  '6798cc558924d60013bc6089': {
                    error: 'Something went wrong',
                  },
                  '6798cc55a962cb0013517766': {
                    uploading: true,
                  },
                  '6798cc67a962cb0013517768': {
                    removing: true,
                  },
                }}
                attachments={[
                  {
                    _id: '6798cc55a962cb0013517764',
                    key: '679356d2e27466001357e86b/attachments/6798cc55a962cb0013517764/a-pdf.pdf',
                    name: 'CA-Amendatory-Endorsement.pdf',
                  },
                  {
                    _id: '6798cc558924d60013bc6089',
                    key: '679356d2e27466001357e86b/attachments/6798cc558924d60013bc6089/file-sample-100kB.doc',
                    name: 'Canine Exclusion.doc',
                  },
                  {
                    _id: '6798cc558924d60013bc6088',
                    key: '679356d2e27466001357e86b/attachments/6798cc558924d60013bc6088/file-summary.pdf',
                    name: "DALL·E 2024-12-18 21.04.51 - A modern and minimalist logo for a brand named 'VSN'. The design should feature sleek, bold typography with clean lines, incorporating a futuristic an",
                  },
                  {
                    _id: '6798cc55a962cb0013517766',
                    key: '679356d2e27466001357e86b/attachments/6798cc55a962cb0013517766/file-example-XLS-1000.xls',
                    name: 'MT 20 09 05 19 Broken Seal.xls',
                  },
                  {
                    _id: '6798cc67a962cb0013517768',
                    key: '679356d2e27466001357e86b/attachments/6798cc67a962cb0013517768/3QAe.gif',
                    name: 'Contractors Broadening.gif',
                  },
                ]}
              />
            </Box>
            <Attachment
              onDownload={() => {}}
              onCompare={() => {}}
              onRemove={() => {}}
              data={{
                _id: '123',
                key: '123',
                name: 'Something.pdf',
              }}
            />
          </HStack>
        </Card.Body>
      </Card.Root>

      <Separator margin="10px 0" />

      {/* Buttons */}
      <Card.Root variant="outline">
        <Card.Header>
          <Text textStyle="xl">Buttons</Text>
        </Card.Header>
        <Card.Body>
          <Text textStyle="md">Primary</Text>
          <Separator margin="10px 0" />

          <HStack wrap="wrap" gap="6" marginBottom="10">
            <Button colorPalette="blue" size="sm">
              <LuPlus />
              Add Form
            </Button>
            <Button colorPalette="red" size="sm">
              <LuTrash2 />
              Delete
            </Button>
            <Button colorPalette="red" size="sm">
              <LuArchive />
              Archive
            </Button>
          </HStack>

          <Text textStyle="md">Secondary</Text>
          <Separator margin="10px 0" />
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <Button variant="outline" colorPalette="blue" size="sm">
              <LuClipboardList />
              Generate Report
            </Button>
            <Button variant="outline" colorPalette="blue" size="sm">
              <LuDownload />
              Download Report
            </Button>
            <Button variant="outline" colorPalette="blue" size="sm">
              <LuRefreshCcw />
              Sync Filings
            </Button>
            <Button variant="outline" loading loadingText="Syncing" colorPalette="blue" size="sm">
              <LuRefreshCcw />
              Sync Filings
            </Button>
          </HStack>
        </Card.Body>
      </Card.Root>
      <Separator margin="10px 0" />

      {/* Menu */}
      <Card.Root variant="outline">
        <Card.Header>
          <Text textStyle="xl">Menu</Text>
        </Card.Header>
        <Card.Body>
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <Menu
              trigger={(
                <Button variant="outline" size="md">
                  Actions
                </Button>
              )}
              options={[
                {
                  label: 'Clone',
                  value: 'clone',
                  icon: <LuCopy />,
                  separator: true,
                },
                {
                  label: 'Delete',
                  value: 'delete',
                  icon: <LuTrash2 />,
                  danger: true,
                },
                {
                  label: 'Archive',
                  value: 'Archive',
                  icon: <LuArchive />,
                  danger: true,
                },
              ]}
            />
            <Menu
              trigger={(
                <Button variant="ghost" size="sm">
                  <LuEllipsisVertical />
                </Button>
              )}
              options={[
                {
                  label: 'Edit',
                  value: 'edit',
                  icon: <LuPencil />,
                },
                {
                  label: 'Clone',
                  value: 'clone',
                  icon: <LuCopy />,
                },
                {
                  label: 'Replaces',
                  value: 'replaces',
                  icon: <LuArrowLeftRight />,
                  separator: true,
                },
                {
                  label: 'Archive',
                  value: 'Archive',
                  icon: <LuArchive />,
                  danger: true,
                },
              ]}
            />

            <Menu
              trigger={(
                <Button variant="ghost" size="sm">
                  <LuEllipsisVertical />
                </Button>
              )}
              options={[
                {
                  label: 'Add Form Status',
                  value: 'add',
                  icon: <LuPlus />,
                },
                {
                  label: 'Edit Form',
                  value: 'edit',
                  icon: <LuPencil />,
                  separator: true,
                },
                {
                  label: 'Archive',
                  value: 'Archive',
                  icon: <LuArchive />,
                  danger: true,
                },
              ]}
            />
          </HStack>
        </Card.Body>
      </Card.Root>
      <Separator margin="10px 0" />

      {/* Tabs */}
      <Card.Root variant="outline">
        <Card.Header>
          <Text textStyle="xl">Tabs</Text>
        </Card.Header>
        <Card.Body>
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <SegmentedControl
              defaultValue="latest_updates"
              items={[
                {
                  value: 'latest_updates',
                  label: (
                    <HStack>
                      <LuCalendarCheck2 />
                      Latest Updates
                    </HStack>
                  ),
                },
                {
                  value: 'upcoming_deadlines',
                  label: (
                    <HStack>
                      <LuCalendarClock />
                      Upcoming Deadlines
                    </HStack>
                  ),
                },
              ]}
            />

            <SegmentedControl
              defaultValue="show_all"
              items={[
                {
                  value: 'show_all',
                  label: (
                    <HStack>
                      <LuList />
                      Show All
                    </HStack>
                  ),
                },
                {
                  value: 'archived',
                  label: (
                    <HStack>
                      <LuArchive />
                      Archived
                    </HStack>
                  ),
                },
                {
                  value: 'active',
                  label: (
                    <HStack>
                      <LuListCheck />
                      Active
                    </HStack>
                  ),
                },
              ]}
            />
          </HStack>

        </Card.Body>
      </Card.Root>
      <Separator margin="10px 0" />

      <Card.Root variant="outline">
        <Card.Body>
          {/* Badge */}
          <Text textStyle="xl">Badge</Text>
          <Separator margin="10px 0" />
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <Badge>Default</Badge>
            <Badge colorPalette="green">Success</Badge>
            <Badge colorPalette="red">Removed</Badge>
            <Badge colorPalette="purple">New</Badge>
          </HStack>

          {/* Dialog */}
          <Text textStyle="xl">Dialog</Text>
          <Separator margin="10px 0" />
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <Button
              variant="outline"
              onClick={() => setChakraDialogOpen(true)}
            >
              Open Dialog
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                ConfirmDialog({
                  title: 'Confirm Deletion',
                  content: 'Are you sure you want to delete this item? This action cannot be undone.',
                  submitText: 'Delete',
                  cancelText: 'Cancel',
                  onSubmit: () => {
                    console.log('yes');
                  },
                  onCancel: () => {
                    console.log('no');
                  },
                  submitButtonProps: {
                    colorPalette: 'red',
                  },
                });
              }}
            >
              Open Confirm
            </Button>
            <Dialog
              title="A title"
              open={chakraDialogOpen}
              onClose={() => setChakraDialogOpen(false)}
            >
              a dialog content
            </Dialog>
          </HStack>

          {/* Drawer */}
          <Text textStyle="xl">Drawer</Text>
          <Separator margin="10px 0" />
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <Button variant="outline" onClick={() => setChakraDrawerOpen(true)}>Open Drawer</Button>
            <Drawer
              title="A title"
              open={chakraDrawerOpen}
              onClose={() => setChakraDrawerOpen(false)}
            >
              a drawer content
            </Drawer>
          </HStack>

          {/* Popover */}
          <Text textStyle="xl">Popover</Text>
          <Separator margin="10px 0" />
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <PopoverRoot>
              <PopoverTrigger asChild>
                <Button size="sm" variant="outline">
                  Click me
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  This is a popover with the same width as the trigger button
                </PopoverBody>
              </PopoverContent>
            </PopoverRoot>
          </HStack>

          {/* Radio */}
          <Text textStyle="xl">Radio</Text>
          <Separator margin="10px 0" />
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <RadioGroup defaultValue="1" colorPalette="blue">
              <HStack gap="6">
                <Radio value="1">Option 1</Radio>
                <Radio value="2">Option 2</Radio>
                <Radio value="3">Option 3</Radio>
              </HStack>
            </RadioGroup>
          </HStack>

          {/* Avatar */}
          <Text textStyle="xl">Avatar</Text>
          <Separator margin="10px 0" />
          <HStack wrap="wrap" gap="6" marginBottom="10">
            <AvatarGroup
              max={4}
              list={USERS}
            />
            <AvatarSelector
              required
              list={USERS}
              selectionList={[]}
              onSave={() => {}}
              trigger={(
                <Button variant="ghost" size="sm">
                  <LuPencil />
                </Button>
              )}
            />
          </HStack>
        </Card.Body>
      </Card.Root>

    </Box>
  );
};

export default Chakra;
